export const countryCodeConverter = [
  { cca2: 'all', cca3: 'all' },
  { cca2: 'AW', cca3: 'ABW' },
  { cca2: 'AF', cca3: 'AFG' },
  { cca2: 'AO', cca3: 'AGO' },
  { cca2: 'AI', cca3: 'AIA' },
  { cca2: 'AX', cca3: 'ALA' },
  { cca2: 'AL', cca3: 'ALB' },
  { cca2: 'AD', cca3: 'AND' },
  { cca2: 'AE', cca3: 'ARE' },
  { cca2: 'AR', cca3: 'ARG' },
  { cca2: 'AM', cca3: 'ARM' },
  { cca2: 'AS', cca3: 'ASM' },
  { cca2: 'AQ', cca3: 'ATA' },
  { cca2: 'TF', cca3: 'ATF' },
  { cca2: 'AG', cca3: 'ATG' },
  { cca2: 'AU', cca3: 'AUS' },
  { cca2: 'AT', cca3: 'AUT' },
  { cca2: 'AZ', cca3: 'AZE' },
  { cca2: 'BI', cca3: 'BDI' },
  { cca2: 'BE', cca3: 'BEL' },
  { cca2: 'BJ', cca3: 'BEN' },
  { cca2: 'BF', cca3: 'BFA' },
  { cca2: 'BD', cca3: 'BGD' },
  { cca2: 'BG', cca3: 'BGR' },
  { cca2: 'BH', cca3: 'BHR' },
  { cca2: 'BS', cca3: 'BHS' },
  { cca2: 'BA', cca3: 'BIH' },
  { cca2: 'BL', cca3: 'BLM' },
  { cca2: 'BY', cca3: 'BLR' },
  { cca2: 'BZ', cca3: 'BLZ' },
  { cca2: 'BM', cca3: 'BMU' },
  { cca2: 'BO', cca3: 'BOL' },
  { cca2: 'BR', cca3: 'BRA' },
  { cca2: 'BB', cca3: 'BRB' },
  { cca2: 'BN', cca3: 'BRN' },
  { cca2: 'BT', cca3: 'BTN' },
  { cca2: 'BV', cca3: 'BVT' },
  { cca2: 'BW', cca3: 'BWA' },
  { cca2: 'CF', cca3: 'CAF' },
  { cca2: 'CA', cca3: 'CAN' },
  { cca2: 'CC', cca3: 'CCK' },
  { cca2: 'CH', cca3: 'CHE' },
  { cca2: 'CL', cca3: 'CHL' },
  { cca2: 'CN', cca3: 'CHN' },
  { cca2: 'CI', cca3: 'CIV' },
  { cca2: 'CM', cca3: 'CMR' },
  { cca2: 'CD', cca3: 'COD' },
  { cca2: 'CG', cca3: 'COG' },
  { cca2: 'CK', cca3: 'COK' },
  { cca2: 'CO', cca3: 'COL' },
  { cca2: 'KM', cca3: 'COM' },
  { cca2: 'CV', cca3: 'CPV' },
  { cca2: 'CR', cca3: 'CRI' },
  { cca2: 'CU', cca3: 'CUB' },
  { cca2: 'CW', cca3: 'CUW' },
  { cca2: 'CX', cca3: 'CXR' },
  { cca2: 'KY', cca3: 'CYM' },
  { cca2: 'CY', cca3: 'CYP' },
  { cca2: 'CZ', cca3: 'CZE' },
  { cca2: 'DE', cca3: 'DEU' },
  { cca2: 'DJ', cca3: 'DJI' },
  { cca2: 'DM', cca3: 'DMA' },
  { cca2: 'DK', cca3: 'DNK' },
  { cca2: 'DO', cca3: 'DOM' },
  { cca2: 'DZ', cca3: 'DZA' },
  { cca2: 'EC', cca3: 'ECU' },
  { cca2: 'EG', cca3: 'EGY' },
  { cca2: 'ER', cca3: 'ERI' },
  { cca2: 'EH', cca3: 'ESH' },
  { cca2: 'ES', cca3: 'ESP' },
  { cca2: 'EE', cca3: 'EST' },
  { cca2: 'ET', cca3: 'ETH' },
  { cca2: 'FI', cca3: 'FIN' },
  { cca2: 'FJ', cca3: 'FJI' },
  { cca2: 'FK', cca3: 'FLK' },
  { cca2: 'FR', cca3: 'FRA' },
  { cca2: 'FO', cca3: 'FRO' },
  { cca2: 'FM', cca3: 'FSM' },
  { cca2: 'GA', cca3: 'GAB' },
  { cca2: 'GB', cca3: 'GBR' },
  { cca2: 'GE', cca3: 'GEO' },
  { cca2: 'GG', cca3: 'GGY' },
  { cca2: 'GH', cca3: 'GHA' },
  { cca2: 'GI', cca3: 'GIB' },
  { cca2: 'GN', cca3: 'GIN' },
  { cca2: 'GP', cca3: 'GLP' },
  { cca2: 'GM', cca3: 'GMB' },
  { cca2: 'GW', cca3: 'GNB' },
  { cca2: 'GQ', cca3: 'GNQ' },
  { cca2: 'GR', cca3: 'GRC' },
  { cca2: 'GD', cca3: 'GRD' },
  { cca2: 'GL', cca3: 'GRL' },
  { cca2: 'GT', cca3: 'GTM' },
  { cca2: 'GF', cca3: 'GUF' },
  { cca2: 'GU', cca3: 'GUM' },
  { cca2: 'GY', cca3: 'GUY' },
  { cca2: 'HK', cca3: 'HKG' },
  { cca2: 'HM', cca3: 'HMD' },
  { cca2: 'HN', cca3: 'HND' },
  { cca2: 'HR', cca3: 'HRV' },
  { cca2: 'HT', cca3: 'HTI' },
  { cca2: 'HU', cca3: 'HUN' },
  { cca2: 'ID', cca3: 'IDN' },
  { cca2: 'IM', cca3: 'IMN' },
  { cca2: 'IN', cca3: 'IND' },
  { cca2: 'IO', cca3: 'IOT' },
  { cca2: 'IE', cca3: 'IRL' },
  { cca2: 'IR', cca3: 'IRN' },
  { cca2: 'IQ', cca3: 'IRQ' },
  { cca2: 'IS', cca3: 'ISL' },
  { cca2: 'IL', cca3: 'ISR' },
  { cca2: 'IT', cca3: 'ITA' },
  { cca2: 'JM', cca3: 'JAM' },
  { cca2: 'JE', cca3: 'JEY' },
  { cca2: 'JO', cca3: 'JOR' },
  { cca2: 'JP', cca3: 'JPN' },
  { cca2: 'KZ', cca3: 'KAZ' },
  { cca2: 'KE', cca3: 'KEN' },
  { cca2: 'KG', cca3: 'KGZ' },
  { cca2: 'KH', cca3: 'KHM' },
  { cca2: 'KI', cca3: 'KIR' },
  { cca2: 'KN', cca3: 'KNA' },
  { cca2: 'KR', cca3: 'KOR' },
  { cca2: 'XK', cca3: 'UNK' },
  { cca2: 'KW', cca3: 'KWT' },
  { cca2: 'LA', cca3: 'LAO' },
  { cca2: 'LB', cca3: 'LBN' },
  { cca2: 'LR', cca3: 'LBR' },
  { cca2: 'LY', cca3: 'LBY' },
  { cca2: 'LC', cca3: 'LCA' },
  { cca2: 'LI', cca3: 'LIE' },
  { cca2: 'LK', cca3: 'LKA' },
  { cca2: 'LS', cca3: 'LSO' },
  { cca2: 'LT', cca3: 'LTU' },
  { cca2: 'LU', cca3: 'LUX' },
  { cca2: 'LV', cca3: 'LVA' },
  { cca2: 'MO', cca3: 'MAC' },
  { cca2: 'MF', cca3: 'MAF' },
  { cca2: 'MA', cca3: 'MAR' },
  { cca2: 'MC', cca3: 'MCO' },
  { cca2: 'MD', cca3: 'MDA' },
  { cca2: 'MG', cca3: 'MDG' },
  { cca2: 'MV', cca3: 'MDV' },
  { cca2: 'MX', cca3: 'MEX' },
  { cca2: 'MH', cca3: 'MHL' },
  { cca2: 'MK', cca3: 'MKD' },
  { cca2: 'ML', cca3: 'MLI' },
  { cca2: 'MT', cca3: 'MLT' },
  { cca2: 'MM', cca3: 'MMR' },
  { cca2: 'ME', cca3: 'MNE' },
  { cca2: 'MN', cca3: 'MNG' },
  { cca2: 'MP', cca3: 'MNP' },
  { cca2: 'MZ', cca3: 'MOZ' },
  { cca2: 'MR', cca3: 'MRT' },
  { cca2: 'MS', cca3: 'MSR' },
  { cca2: 'MQ', cca3: 'MTQ' },
  { cca2: 'MU', cca3: 'MUS' },
  { cca2: 'MW', cca3: 'MWI' },
  { cca2: 'MY', cca3: 'MYS' },
  { cca2: 'YT', cca3: 'MYT' },
  { cca2: 'NA', cca3: 'NAM' },
  { cca2: 'NC', cca3: 'NCL' },
  { cca2: 'NE', cca3: 'NER' },
  { cca2: 'NF', cca3: 'NFK' },
  { cca2: 'NG', cca3: 'NGA' },
  { cca2: 'NI', cca3: 'NIC' },
  { cca2: 'NU', cca3: 'NIU' },
  { cca2: 'NL', cca3: 'NLD' },
  { cca2: 'NO', cca3: 'NOR' },
  { cca2: 'NP', cca3: 'NPL' },
  { cca2: 'NR', cca3: 'NRU' },
  { cca2: 'NZ', cca3: 'NZL' },
  { cca2: 'OM', cca3: 'OMN' },
  { cca2: 'PK', cca3: 'PAK' },
  { cca2: 'PA', cca3: 'PAN' },
  { cca2: 'PN', cca3: 'PCN' },
  { cca2: 'PE', cca3: 'PER' },
  { cca2: 'PH', cca3: 'PHL' },
  { cca2: 'PW', cca3: 'PLW' },
  { cca2: 'PG', cca3: 'PNG' },
  { cca2: 'PL', cca3: 'POL' },
  { cca2: 'PR', cca3: 'PRI' },
  { cca2: 'KP', cca3: 'PRK' },
  { cca2: 'PT', cca3: 'PRT' },
  { cca2: 'PY', cca3: 'PRY' },
  { cca2: 'PS', cca3: 'PSE' },
  { cca2: 'PF', cca3: 'PYF' },
  { cca2: 'QA', cca3: 'QAT' },
  { cca2: 'RE', cca3: 'REU' },
  { cca2: 'RO', cca3: 'ROU' },
  { cca2: 'RU', cca3: 'RUS' },
  { cca2: 'RW', cca3: 'RWA' },
  { cca2: 'SA', cca3: 'SAU' },
  { cca2: 'SA', cca3: 'KSA' },
  { cca2: 'SD', cca3: 'SDN' },
  { cca2: 'SN', cca3: 'SEN' },
  { cca2: 'SG', cca3: 'SGP' },
  { cca2: 'GS', cca3: 'SGS' },
  { cca2: 'SJ', cca3: 'SJM' },
  { cca2: 'SB', cca3: 'SLB' },
  { cca2: 'SL', cca3: 'SLE' },
  { cca2: 'SV', cca3: 'SLV' },
  { cca2: 'SM', cca3: 'SMR' },
  { cca2: 'SO', cca3: 'SOM' },
  { cca2: 'PM', cca3: 'SPM' },
  { cca2: 'RS', cca3: 'SRB' },
  { cca2: 'SS', cca3: 'SSD' },
  { cca2: 'ST', cca3: 'STP' },
  { cca2: 'SR', cca3: 'SUR' },
  { cca2: 'SK', cca3: 'SVK' },
  { cca2: 'SI', cca3: 'SVN' },
  { cca2: 'SE', cca3: 'SWE' },
  { cca2: 'SZ', cca3: 'SWZ' },
  { cca2: 'SX', cca3: 'SXM' },
  { cca2: 'SC', cca3: 'SYC' },
  { cca2: 'SY', cca3: 'SYR' },
  { cca2: 'TC', cca3: 'TCA' },
  { cca2: 'TD', cca3: 'TCD' },
  { cca2: 'TG', cca3: 'TGO' },
  { cca2: 'TH', cca3: 'THA' },
  { cca2: 'TJ', cca3: 'TJK' },
  { cca2: 'TK', cca3: 'TKL' },
  { cca2: 'TM', cca3: 'TKM' },
  { cca2: 'TL', cca3: 'TLS' },
  { cca2: 'TO', cca3: 'TON' },
  { cca2: 'TT', cca3: 'TTO' },
  { cca2: 'TN', cca3: 'TUN' },
  { cca2: 'TR', cca3: 'TUR' },
  { cca2: 'TV', cca3: 'TUV' },
  { cca2: 'TW', cca3: 'TWN' },
  { cca2: 'TZ', cca3: 'TZA' },
  { cca2: 'UG', cca3: 'UGA' },
  { cca2: 'UA', cca3: 'UKR' },
  { cca2: 'UM', cca3: 'UMI' },
  { cca2: 'UY', cca3: 'URY' },
  { cca2: 'US', cca3: 'USA' },
  { cca2: 'UZ', cca3: 'UZB' },
  { cca2: 'VA', cca3: 'VAT' },
  { cca2: 'VC', cca3: 'VCT' },
  { cca2: 'VE', cca3: 'VEN' },
  { cca2: 'VG', cca3: 'VGB' },
  { cca2: 'VI', cca3: 'VIR' },
  { cca2: 'VN', cca3: 'VNM' },
  { cca2: 'VU', cca3: 'VUT' },
  { cca2: 'WF', cca3: 'WLF' },
  { cca2: 'WS', cca3: 'WSM' },
  { cca2: 'YE', cca3: 'YEM' },
  { cca2: 'ZA', cca3: 'ZAF' },
  { cca2: 'ZM', cca3: 'ZMB' },
  { cca2: 'ZW', cca3: 'ZWE' },
];
