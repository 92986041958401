import vars from "../../../environment";

const axios = require("axios");
if (process.env.REACT_APP_CONTEXT !== "local")
  axios.defaults.withCredentials = true
export const fetchCountries = async () => {
  return await axios.post(`${vars.referralAPI}/countries/all`);
};

export const fetchServices = async () => {
  return await axios.post(`${vars.referralAPI}/services/all`);
};
