/**
 *
 * Container
 *
 */

import styled from 'styled-components';


export default styled.div`
  max-width: 1380px;
  margin: 0 auto;
  display: flex;
  padding: 0 30px;
  min-height: 100%;
  background: "#f9f9fc";
  flex-direction: column;
  background-color: #f9f9fc;
  //overflow-x: hidden;
`