/**
 *
 * LevelPage
 *
 */

import React from "react";
import Breadcrumbs from 'components/Breadcrumbs';
import CountryActivationList from "./CountryActivationList";

const CountryActivationPage = () => {
  return (
    <div>
      <Breadcrumbs title="Countries activation" breads={window.location.pathname} />
      <div style={{overflowX: "hidden", position: "relative"}}>
      <CountryActivationList/>
      </div>
    </div>
  );
}

export default (CountryActivationPage);
