import styled from 'styled-components';

export default styled.div`
    display: flex;
    flex-direction: row;
    flex: 1;
    flex-wrap: wrap;
    margin: ${props => props.margin};
    align-items: ${props => props.alignItems};
    justify-content: ${props => props.justifyContent};
    padding: ${props => props.padding};
    & .border {
        border-right: 3px solid gray;
        margin-left: 14px;
        flex: 1;
        margin-right: 20px;
    };
    border-bottom : ${props => props.borderBottom};
`