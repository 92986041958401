import styled from 'styled-components';

const Wrapper = styled.footer`
  display: flex;
  justify-content: space-between;
  padding: 3em 0;
  background-image: linear-gradient(to right,#1A0244,#1A0244);
  padding: 50px 150px;
`;

export default Wrapper;
