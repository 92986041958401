import React from "react";
import PropTypes from "prop-types";
import { default as ReactSelect } from "react-select";

const CustomSelect = props => {
  if (props.allowSelectAll) {
    return (
      <ReactSelect
        {...props}
        options={[props.allOption, ...props.options]}
        onChange={selected => {
          if (
            selected !== null &&
            selected.length > 0 &&
            selected[selected.length - 1][props.valSelector] === props.allOption[props.valSelector]
          ) {
          let groupedOptions = []
           props.options.map(val => {
             
              if(val.options !== undefined)
                val.options.map(value => {
                  groupedOptions.push(value)
                })
           })


            if(props.isGrouped) return props.onChange(groupedOptions);//if the select is grouped return groupedOptions
            return props.onChange(props.options);
          }
          return props.onChange(selected);
        }}
      />
    );
  }

  return <ReactSelect {...props} />;
};

CustomSelect.propTypes = {
  options: PropTypes.array,
  value: PropTypes.any,
  onChange: PropTypes.func,
  allowSelectAll: PropTypes.bool,
  allOption: PropTypes.shape({
    name: PropTypes.object,
    value: PropTypes.string
  }),
  valSelector: PropTypes.string
};

CustomSelect.defaultProps = {
  
  allOption: {
    name: "Select all",
    value: "*"
  },
  valSelector: 'value'
};

export default CustomSelect;