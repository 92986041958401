export default {
  en: {
    "Error.CountryActivation.serviceName.required": "Service name required",

    "Error.CountryActivation.nbActionsToRef.Positive": "Number actions to referrals must be positive",
    "Error.CountryActivation.nbActionsToRef.required": "Number actions to referrals required",

    "Error.CountryActivation.maxEarning.Positive": "Max earning must be positive",
    "Error.CountryActivation.maxEarning.required": "Max earning required",

    "Error.CountryActivation.referrerAmountEarning.Positive": "Referrer amount earning must be positive",
    "Error.CountryActivation.referrerAmountEarning.required": "Referrer amount earning required",

    "Error.CountryActivation.referredAmountEarning.Positive": "Referred amount earning must be positive",
    "Error.CountryActivation.referredAmountEarning.required": "Referred amount earning required",

    "Error.CountryActivation.validDays.Positive": "Valid days must be positive",
    "Error.CountryActivation.validDays.required": "Valid days required",

    "Error.CountryActivation.yassirCouponName.required": "Yassir Coupon name required",
    "Error.CountryActivation.yassirSegmentName.required": "Yassir Segment name required",
    "Error.CountryActivation.expressCouponName.required": "Express Coupon name required",
  },
  fr: {
    "Error.CountryActivation.serviceName.required": "Service doit être selectionné",

    "Error.CountryActivation.nbActionsToRef.Positive": "Nombre de consommation doit être un nombre positif",
    "Error.CountryActivation.nbActionsToRef.required": "Nombre de consommation requis",

    "Error.CountryActivation.maxEarning.Positive": "Valeur de gain  doit être un nombre positif",
    "Error.CountryActivation.maxEarning.required": "Valeur de gain requis",

    "Error.CountryActivation.referrerAmountEarning.Positive": "Gain parrain doit être un nombre positif",
    "Error.CountryActivation.referrerAmountEarning.required": "Gain parrain  requis",

    "Error.CountryActivation.referredAmountEarning.Positive": "Gain parrainé doit être un nombre positif",
    "Error.CountryActivation.referredAmountEarning.required": "Gain parrainé requis",

    "Error.CountryActivation.validDays.Positive": "Nombre de jours de validation doit être un nombre positif",
    "Error.CountryActivation.validDays.required": "Nombre de jours de validation requis",

    "Error.CountryActivation.yassirCouponName.required": "Nom du coupon Yassir requis",
    "Error.CountryActivation.yassirSegmentName.required": "Nom du segment Yassir requis",
    "Error.CountryActivation.expressCouponName.required": "Nom du coupon Express requis",
  },
};
