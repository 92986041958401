import React, { useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import DialogTitle from "@material-ui/core/DialogTitle";
import Switch from "@material-ui/core/Switch";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import { Formik } from "formik";
import PropTypes from "prop-types";
import validationSchema from "./validationSchema";
import useStyles from "./styles";
import Separator from "components/Separator";
// import { fetchServices } from "../../ParrainsListPage/tableHeader/fetchData";
import { fetchServices } from "../../Commun/fetchData";

import {
  saveCountrySettings,
  getCountrySettingByCountryAndService,
} from "../actions";
import messages from "./messages";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from '@material-ui/icons/Close';
import CircularProgress from "@material-ui/core/CircularProgress";
const propTypes = {
  currentCountry: PropTypes.object.isRequired,
  openSettingDialog: PropTypes.bool.isRequired,
  handleCloseSettingDialog: PropTypes.func.isRequired,
  localeLang: PropTypes.string.isRequired,
};

const Alert = (props) => {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
};
const SettingDialog = ({
  currentCountry,
  openSettingDialog,
  handleCloseSettingDialog,
  localeLang,
}) => {
  const [services, setServices] = useState([]);
  const [gainTypes, setGainTypes] = useState(["COUPON","MONEY"]);
  const [openNotification, setOpenNotification] = useState(false);
  const [settingSavedSuccess, setSettingSavedSuccess] = useState(false);
  const [loadingButton, setLoadingButton] = useState(true);
  const classes = useStyles();

  useEffect(() => {
    fetchServices()
      .then((response) => {
        setServices(response.data && response.data.count && response.data.list);
      })
      .catch((err) =>
        console.error("%c⧭ error fetching services: ", "color: #997326", err)
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSaveClick = (values) => {
    saveCountrySettings({ ...values, countryCode: currentCountry.code })
      .then((response) => {
        if (response.status === 201) {
          setOpenNotification(true);
          setSettingSavedSuccess(true);
        }
        setTimeout(() => {
          setOpenNotification(false);
        }, 3000);

        setTimeout(() => {
          handleCloseSettingDialog();
        }, 1000);
      })
      .catch((error) => {
        setSettingSavedSuccess(false);
        console.error("%c⧭", "color: #364cd9", error);
      });
  };
  const handleServiceChanging = (e, setFieldValue) => {
    setLoadingButton(true)
    //return
    getCountrySettingByCountryAndService(currentCountry.code, e.target.value)
      .then((response) => {
        if (response.status === 200 && response.data) {
          "isRefActivated" in response.data &&
            setFieldValue("isRefActivated", response.data.isRefActivated);
          "maxEarning" in response.data &&
            setFieldValue("maxEarning", response.data.maxEarning);
          "nbActionsToRef" in response.data &&
            setFieldValue("nbActionsToRef", response.data.nbActionsToRef);
          "referredAmountEarning" in response.data &&
            setFieldValue(
              "referredAmountEarning",
              response.data.referredAmountEarning
            );
          "referrerAmountEarning" in response.data &&
            setFieldValue(
              "referrerAmountEarning",
              response.data.referrerAmountEarning
            );
          "validDays" in response.data &&
            setFieldValue("validDays", response.data.validDays);
          "yassirCouponName" in response.data &&
            setFieldValue("yassirCouponName", response.data.yassirCouponName);

          "yassirSegmentName" in response.data &&
            setFieldValue("yassirSegmentName", response.data.yassirSegmentName);

          "expressCouponName" in response.data &&
            setFieldValue("expressCouponName", response.data.expressCouponName);
          "canBeEarnedForAllServices" in response.data &&
            setFieldValue("canBeEarnedForAllServices", response.data.canBeEarnedForAllServices);
          "isCrossAccountTypeReferralEnabled" in response.data &&
            setFieldValue("isCrossAccountTypeReferralEnabled", response.data.isCrossAccountTypeReferralEnabled);
          "referrerGainType" in response.data &&
            setFieldValue("referrerGainType", response.data.referrerGainType);
        }
        else{
            setFieldValue("isRefActivated", "");
            setFieldValue("maxEarning", "");
            setFieldValue("nbActionsToRef", "");
            setFieldValue("referredAmountEarning", "");
            setFieldValue("referrerAmountEarning","");
            setFieldValue("validDays", "");
            setFieldValue("yassirCouponName", "");
            setFieldValue("yassirSegmentName", "");
            setFieldValue("expressCouponName", "");
            setFieldValue("canBeEarnedForAllServices", "");
            setFieldValue("isCrossAccountTypeReferralEnabled", "");
        }
        
        setTimeout(() => {
          setLoadingButton(false)
        }, 2500);
      })
      .catch((err) => {
        console.error(
          "%c⧭ getCountrySettingByCountryAndService ==> ",
          "color: #73998c",
          err
        );
        setFieldValue("isRefActivated", "");
        setFieldValue("maxEarning", "");
        setFieldValue("nbActionsToRef", "");
        setFieldValue("referredAmountEarning", "");
        setFieldValue("referrerAmountEarning","");
        setFieldValue("validDays", "");
        setFieldValue("yassirCouponName", "");
        setFieldValue("yassirSegmentName", "");
        setFieldValue("expressCouponName", "");
        setFieldValue("canBeEarnedForAllServices", "");
        setFieldValue("isCrossAccountTypeReferralEnabled", "");
        setTimeout(() => {
          setLoadingButton(false)
        }, 2500);
      });
  };
  const initialValues = {
    serviceName: "",
    nbActionsToRef: 0,
    maxEarning: 0,
    referrerAmountEarning: 0,
    referredAmountEarning: 0,
    validDays: 0,
    isRefActivated: false,
    yassirCouponName:"",
    yassirSegmentName:"",
    expressCouponName:"",
    canBeEarnedForAllServices: false,
    isCrossAccountTypeReferralEnabled: false,
    referrerGainType:"COUPON"
  };
  return (
    <div>
      <Dialog
        disableEscapeKeyDown
        disableBackdropClick
        fullWidth
        maxWidth="sm"
        open={openSettingDialog}
        onClose={handleCloseSettingDialog}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          Settings:{" "}
          <span
            style={{
              fontWeight: 200,
            }}
          >
            {currentCountry.name}
          </span>
          <IconButton
          style={{ position: "absolute", top: "-5px", right: "-5px" }}
          onClick={handleCloseSettingDialog}
        >
          <CloseIcon />
        </IconButton>
        </DialogTitle>
        <DialogContent>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={(values) => {
              handleSaveClick(values);
              handleCloseSettingDialog();
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              setFieldValue,
            }) => (
              <form onSubmit={handleSubmit}>
                <FormControl fullWidth style={{ paddingTop: 10 }}>
                  <TextField
                    select={!!services.length}
                    variant="outlined"
                    label={"Service"}
                    name="serviceName"
                    disabled={!services.length}
                    onChange={(e) => {
                      handleChange(e);
                      handleServiceChanging(e, setFieldValue);
                    }}
                    onBlur={handleBlur}
                    value={values.serviceName}
                    fullWidth
                    error={touched.serviceName && Boolean(errors.serviceName)}
                    helperText={
                      touched.serviceName && errors.serviceName
                        ? messages[localeLang][errors.serviceName]
                        : null
                    }
                  >
                    {!!services.length &&
                      services.map((service) => (
                        service.name == "mobility" || service.name == "yassir-vtc" ?
                        <MenuItem
                          // eslint-disable-next-line no-underscore-dangle
                          key={`${service._id}`}
                          value={service.name}
                        >
                          <div
                            style={{
                              float: "left",
                              lineHeight: "19px",
                            }}
                          >
                            {service.displayName}
                          </div>
                        </MenuItem>
                        : null
                      ))
                      }
                  </TextField>
                </FormControl>

                {
                    values.serviceName == "" ?
                    <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: 150,
                      padding: '7px 20px',
                      fontSize: 13, fontWeight: '300',
                    }}
                ></div>
                    :
                    loadingButton ?
                    <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: 150,
                      padding: '7px 20px',
                      fontSize: 13, fontWeight: '300',
                    }}
                >
                 <CircularProgress size={20} thickness={5} style={{'color': 'black'}} />
                </div>
                    
                    :
                    <>

                <div
                  style={{
                    marginTop: 35,
                    marginBottom: 5,
                  }}
                >
                  General Settings
                </div>
                <div style={{ paddingLeft: 20 }}>
                <FormControl
                  fullWidth
                  margin="dense"
                  className={classes.switch}
                >
                  <FormControlLabel
                    control={
                      <Switch
                        checked={values.isRefActivated}
                        value={values.isRefActivated}
                        name="isRefActivated"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        color="primary"
                        width={33}
                        height={18}
                      />
                    }
                    label={"Referral system active? "}
                  />
                </FormControl>
                <FormControl
                  fullWidth
                  margin="dense"
                  className={classes.switch}
                  style={{
                    visibility:"hidden",
                   display: "none"
                  }}
                >
                  <FormControlLabel
                 
                    control={
                      <Switch
                        checked={false}/* {values.canBeEarnedForAllServices} */
                        value={false}/* {values.canBeEarnedForAllServices} */
                        name="canBeEarnedForAllServices"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        color="primary"
                        width={33}
                        height={18}
                      />
                    }
                    label={"Can be earned by all services? "}
                  />
                </FormControl>

                <FormControl
                  fullWidth
                  margin="dense"
                  className={classes.switch}
                  style={{
                    visibility:"hidden",
                   display: "none"
                  }}
                >
                  <FormControlLabel
                    control={
                      <Switch
                        checked={false}/* {values.isCrossAccountTypeReferralEnabled} */
                        value={false}/* {values.isCrossAccountTypeReferralEnabled} */
                        name="isCrossAccountTypeReferralEnabled"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        color="primary"
                        width={33}
                        height={18}
                      />
                    }
                    label={"Is Cross Account Type Referral Enabled? "}
                  />
                </FormControl>
                </div>
                <Separator width={"100%"} border={"1px solid #ebedf2"} margin={"0 0 20px"}/>
                <div
                  style={{
                    marginTop: 35,
                    marginBottom: 5,
                  }}
                >
                  Referrer Reward
                </div>
                <FormControl fullWidth margin="dense">
                  <TextField
                    variant="outlined"
                    label={"Minimum trips to enable referral"}
                    type="number"
                    name="nbActionsToRef"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.nbActionsToRef}
                    fullWidth
                    error={
                      touched.nbActionsToRef && Boolean(errors.nbActionsToRef)
                    }
                    helperText={
                      touched.nbActionsToRef && errors.nbActionsToRef
                        ? messages[localeLang][errors.nbActionsToRef]
                        : null
                    }
                  />
                </FormControl>
                <FormControl fullWidth margin="dense">
                  <TextField
                    variant="outlined"
                    label={"Maximum amount of referrable people"}
                    type="number"
                    name="maxEarning"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.maxEarning}
                    fullWidth
                    error={touched.maxEarning && Boolean(errors.maxEarning)}
                    helperText={
                      touched.maxEarning && errors.maxEarning
                        ? messages[localeLang][errors.maxEarning]
                        : null
                    }
                  />
                </FormControl>
                <FormControl fullWidth 
                style={{ paddingTop: 10,
                  visibility:"hidden",
                  display: "none"
                }}
               
                >
                  <TextField
                    select={!!gainTypes.length}
                    variant="outlined"
                    label={"Earning Type"}
                    name="referrerGainType"
                    disabled={!gainTypes.length}
                    onChange={(e) => {
                      handleChange(e);
                      //handleServiceChanging(e, setFieldValue);
                    }}
                    onBlur={handleBlur}
                    value={"COUPON"}/* {values.referrerGainType} */
                    fullWidth
                    error={touched.referrerGainType && Boolean(errors.referrerGainType)}
                    helperText={
                      touched.referrerGainType && errors.referrerGainType
                        ? messages[localeLang][errors.referrerGainType]
                        : null
                    }
                  >
                    {!!gainTypes.length &&
                      gainTypes.map((gainType) => (
                        <MenuItem
                          // eslint-disable-next-line no-underscore-dangle
                          key={`${gainType}`}
                          value={gainType}
                        >
                          <div
                            style={{
                              float: "left",
                              lineHeight: "19px",
                            }}
                          >
                            {gainType}
                          </div>
                        </MenuItem>
                      ))}
                  </TextField>
                </FormControl>
                <FormControl fullWidth margin="dense">
                  <TextField
                    variant="outlined"
                    label={"Coupon reward amount in local currency"}
                    type="number"
                    name="referrerAmountEarning"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.referrerAmountEarning}
                    fullWidth
                    error={
                      touched.referrerAmountEarning &&
                      Boolean(errors.referrerAmountEarning)
                    }
                    helperText={
                      touched.referrerAmountEarning &&
                      errors.referrerAmountEarning
                        ? messages[localeLang][errors.referrerAmountEarning]
                        : null
                    }
                  />
                </FormControl>
                <FormControl fullWidth margin="dense">
                  <TextField
                    variant="outlined"
                    label={"Number of days the coupon will be valid for"}
                    type="number"
                    name="validDays"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.validDays}
                    fullWidth
                    error={touched.validDays && Boolean(errors.validDays)}
                    helperText={
                      touched.validDays && errors.validDays
                        ? messages[localeLang][errors.validDays]
                        : null
                    }
                  />
                </FormControl>
                <br/><br/>
                <Separator width={"100%"} border={"1px solid #ebedf2"} margin={"0 0 20px"}/>
                <div
                  style={{
                    marginTop: 35,
                    marginBottom: 5,
                  }}
                >
                  Referred Reward
                </div>
               

                {/* <FormControl fullWidth margin="dense">
                  <TextField
                    variant="outlined"
                    label={"Referred gain"}
                    type="number"
                    name="referredAmountEarning"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.referredAmountEarning}
                    fullWidth
                    error={
                      touched.referredAmountEarning &&
                      Boolean(errors.referredAmountEarning)
                    }
                    helperText={
                      touched.referredAmountEarning &&
                      errors.referredAmountEarning
                        ? messages[localeLang][errors.referredAmountEarning]
                        : null
                    }
                  />
                </FormControl> */}
                
                <FormControl fullWidth margin="dense"
                 style={{
                  visibility:"hidden",
                 display: "none"
                }}
                >
                  <TextField
                    variant="outlined"
                    label={"Yassir Coupon Name"}
                    type="string"
                    name="yassirCouponName"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.yassirCouponName}
                    fullWidth
                    error={touched.yassirCouponName && Boolean(errors.yassirCouponName)}
                    helperText={
                      touched.yassirCouponName && errors.yassirCouponName
                        ? messages[localeLang][errors.yassirCouponName]
                        : null
                    }
                  />
                </FormControl>
                <FormControl fullWidth margin="dense">
                  <TextField
                    variant="outlined"
                    label={"Coupon Segment Name"}
                    type="string"
                    name="yassirSegmentName"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.yassirSegmentName}
                    fullWidth
                    error={touched.yassirSegmentName && Boolean(errors.yassirSegmentName)}
                    helperText={
                      touched.yassirSegmentName && errors.yassirSegmentName
                        ? messages[localeLang][errors.yassirSegmentName]
                        : null
                    }
                  />
                </FormControl>
                {/* <FormControl fullWidth margin="dense">
                  <TextField
                    variant="outlined"
                    label={"Express Coupon Name"}
                    type="string"
                    name="expressCouponName"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.expressCouponName}
                    fullWidth
                    error={touched.expressCouponName && Boolean(errors.expressCouponName)}
                    helperText={
                      touched.expressCouponName && errors.expressCouponName
                        ? messages[localeLang][errors.expressCouponName]
                        : null
                    }
                  />
                </FormControl> */}
                
                </>
                }


                <DialogActions>
                  <Button onClick={handleCloseSettingDialog} color="secondary">
                    Cancel
                  </Button>

                  <Button
                    type="submit"
                    color="primary"
                   /*  disabled={
                      Object.keys(touched).length === 0 ||
                      (Object.keys(errors).length !== 0 &&
                        Object.keys(touched).length !== 0)
                    } */
                  >
                    Save
                  </Button>
                </DialogActions>
              </form>
            )}
          </Formik>
        </DialogContent>
      </Dialog>
      <Snackbar open={openNotification} autoHideDuration={3000}>
        <Alert severity={settingSavedSuccess ? "success" : "error"}>
          {settingSavedSuccess
            ? "Setting saved successfully!!"
            : "Saving Setting failed"}
        </Alert>
      </Snackbar>
    </div>
  );
};

SettingDialog.propTypes = propTypes;

export default SettingDialog;
