import styled from 'styled-components';

export default styled.div`
    width: 45px;
    height: 45px;
    align-self: center;
    margin: 0 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
`