/**
 *
 * Separator
 *
 */
import styled from 'styled-components';

export default styled.div`
width: ${props => props.width};
border: ${props => props.border}; 
margin: ${props => props.margin};
`;
