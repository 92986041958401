import React, { useState, useRef, useEffect } from "react";
import styled from "styled-components";
import { NavLink } from "react-router-dom";
import TextInput from "components/TextInput";
import HsSelect from "components/HsSelect";
import { CSVLink } from "react-csv/lib";
import { FaChartBar } from "react-icons/fa";
import { IoMdAdd, IoIosSearch, IoIosClose } from "react-icons/io";
import { CloudDownload } from "@material-ui/icons";
import { fetchCountries, fetchServices } from "../../Commun/fetchData";
import {
  MuiPickersUtilsProvider,
  // DatePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

const Download = styled(CloudDownload)`
  color: #F92C78;
  transition: 0.5s ease !important;
  cursor: pointer;
  margin-right: 5px;
  // &:hover {
  //   color: white;
  //   background-color: #5d78ff;
  // }
`;

var allOptions = {
  name: { en: "Select all", fr: "Sélectionner tout", ar: "إختيار الجميع" },
  value: "*",
};
var allOptionsCategories = {
  display_name: {
    en: "Select all",
    fr: "Sélectionner tout",
    ar: "إختيار الجميع",
  },
  value: "*",
};
const langList = [
  { name: "Français", code: "fr" },
  { name: "العربية", code: "ar" },
  { name: "English", code: "en" },
];

const colourStyles = {
  control: (styles) => ({ ...styles, backgroundColor: "white" }),

  multiValue: (styles, { data }) => {
    return {
      ...styles,
      backgroundColor: "rgba(93, 120, 255, 0.1)",
    };
  },
  multiValueLabel: (styles, { data }) => ({
    ...styles,
    color: "#5d78ff",
  }),
  multiValueRemove: (styles, { data }) => ({
    ...styles,
    color: "#5d78ff",
    ":hover": {
      backgroundColor: "#5d78ff",
      color: "white",
    },
  }),
};

const TableHeader = ({
  title,
  totalRecords,
  addNewLink,
  selectedLang,
  selectLang,
  selectServices,
  selectedStatus,
  selectStatus,
  selectCountries,
  _handleKeyDownSearch,
  clearSearch,
  _handleChangeInputSearch,
  setFilterDates,
}) => {
  const status = [
    { name: "Pending", value: 0 },
    { name: "Completed", value: 1 }
  ];

  const headers = [
    { label: "Title", key: "title" },
    { label: "Category", key: "category" },
    { label: "Description", key: "description" },
    { label: "Plans", key: "levels" },
    { label: "Start date", key: "to_date" },
    { label: "End date", key: "from_date" },
    { label: "Transaction", key: "transaction_value" },
    { label: "Active", key: "is_active" },
  ];

  const csvLink = useRef(null);
  const searchRef = useRef(null);
  const [countries, setCountries] = useState([]);
  const [services, setServices] = useState([]);
  const [dataToDownload, setDataToDownload] = useState([]);
  const dateBeforeMonth = new Date();
  const today = new Date(
    dateBeforeMonth.getFullYear(),
    dateBeforeMonth.getMonth(),
    dateBeforeMonth.getDate(),
    0,
    0,
    0
  );

  dateBeforeMonth.setMonth(dateBeforeMonth.getMonth() - 1);
  const [fromDate, setFromDate] = useState(dateBeforeMonth.toISOString());
  const [toDate, setToDate] = useState((new Date()).toISOString());

  useEffect(() => {
    fetchCountries()
      .then((response) => {
        setCountries(
          response.data && response.data.count && response.data.list
        );
      })
      .catch((err) =>
        console.error("%c⧭ error fetching countries: ", "color: #997326", err)
      );

    fetchServices()
      .then((response) => {
        setServices(response.data && response.data.count && response.data.list);
      })
      .catch((err) =>
        console.error("%c⧭ error fetching services: ", "color: #997326", err)
      );
  }, []);

 useEffect(() => {
  if (fromDate <= toDate)
    setFilterDates(fromDate, toDate);
  }, [fromDate, toDate]);
  return (
    <>
      <div
        style={{
          flexDirection: "row",
          display: "flex",
          alignItems: "center",
          height: 65,
          position: "relative",
          padding: "0 30px",
          borderBottom: "1px solid #ebedf2",
        }}
      >
        <FaChartBar color="#F92C78" style={{ marginRight: 10 }} />
        <h3 style={{ color: "#48465b", fontSize: 16, fontWeight: "400" }}>
          {title}
        </h3>
        <div
          style={{
            width: 1,
            height: 20,
            backgroundColor: "#dbdce7",
            marginLeft: 10,
            marginRight: 10,
          }}
        />
        <p style={{ fontWeight: "300", fontSize: 14, color: "#959cb6" }}>
          {" "}
          {totalRecords} Total
        </p>

        <div
          style={{
            position: "absolute",
            right: 30,
            alignItems: "center",
            display: "flex",
          }}
        >
          {/* <div style={{ minWidth: 140, marginRight: 10 }}>
            <HsSelect
              value={selectedLang}
              onChange={selectLang}
              options={langList}
              getOptionLabel={(option) => option.name}
              getOptionValue={(option) => option.code}
              marginTop={"0px"}
              height={"30px"}
              fontSize={"13px"}
              padding={"0px"}
              zIndex={"1000"}
              placeholder="Language"
              className="okay"
              classNamePrefix="levels"
              name={"lang"}
            />
          </div> */}

          <CSVLink
            style={{
              display: "none",
            }}
            filename={"Parrains.csv"}
            data={dataToDownload}
            headers={headers}
            ref={csvLink}
            target="_blank"
          />
          <div
            // onClick={
            //   () => fetchDataToDownload()
            // }
            style={{
              borderRadius: 4,
              backgroundColor: "#F8F8F9",
              padding: "5px 10px 6px 8px",
              fontWeight: "200",
              fontSize: 13,
              textDecoration: "none",
              color: "#F92C78",
              alignItems: "center",
              display: "flex",
              cursor: "pointer",
            }}
          >
            <Download />
            Download
          </div>

          {/* <NavLink
            style={{
              border: "none",
              borderRadius: 4,
              backgroundColor: "#F92C78",
              color: "white",
              padding: "7px 10px 7px 5px",
              fontSize: 13,
              fontWeight: "200",
              marginLeft: 10,
              cursor: "pointer",
              textDecoration: "none",
              alignItems: "center",
              display: "flex",
            }}
            to={addNewLink}
          >
            <IoMdAdd color="white" style={{ marginRight: 5, fontSize: 20 }} />
            Add New
          </NavLink> */}
        </div>
      </div>

      <div
        style={{
          flexDirection: "row",
          display: "flex",
          flexWrap: "wrap",
          flex: 1,
          alignItems: "center",
          marginBottom: 0,
          marginTop: 0,
          padding: "10px 30px",
        }}
      >
        <div
          style={{
            marginRight: 20,
            position: "relative",
            alignItems: "center",
            marginBottom: 0,
          }}
        >
          <IoIosSearch
            color="#74788d"
            style={{
              position: "absolute",
              left: 10,
              fontSize: 15,
              top: "50%",
              transform: "translateY(-50%)",
            }}
          />
          <TextInput
            ref={searchRef}
            onKeyDown={_handleKeyDownSearch}
            onChange={(e) => {
              _handleChangeInputSearch(e);
              const clearSearch = document.querySelector(".clearSearch");
              !!e.target.value && clearSearch
                ? (clearSearch.style.visibility = "visible")
                : (clearSearch.style.visibility = "hidden");
            }}
            name="search"
            // value={search}
            type="text"
            placeholder="Search..."
            width={"230px"}
            style={{ fontSize: 13, paddingLeft: 35, margin: 0 }}
          />
          <IoIosClose
            className="clearSearch"
            onClick={e => {
              const clear = document.querySelector(".clearSearch");
              !!e.target.value && clear
                ? (clear.style.visibility = "visible")
                : (clear.style.visibility = "hidden");
              clearSearch(e);
              searchRef.current.value = "";

            }}
            color="#74788d"
            style={{
              visibility:"hidden",
              position: "absolute",
              right: 10,
              fontSize: 30,
              top: "50%",
              transform: "translateY(-50%)",
              cursor: "pointer",
            }}
          />
        </div>

        <div style={{ marginRight: 20, marginBottom: 0 }}>
          <p style={{ fontWeight: "300", fontSize: 14, color: "#959cb6" }}>
            Filters :{" "}
          </p>
        </div>

        <div style={{ minWidth: 200, marginRight: 20, marginBottom: 0 }}>
          <HsSelect
            onChange={selectCountries}
            options={countries}
            getOptionLabel={(option) => option.name}
            getOptionValue={(option) => option.code}
            isMulti={false}
            maxMenuHeight={250}
            //allowSelectAll={false}
            marginTop={"0px"}
            height={"33px"}
            fontSize={"13px"}
            padding={"0px"}
            zIndex={"1000"}
            placeholder="Countries"
           /*  className="okay"
            classNamePrefix="levels" */
            name={"countries"}
            styles={colourStyles}
            isClearable
          />
        </div>
        <div style={{ minWidth: 200, marginRight: 20, marginBottom: 0 }}>
          <HsSelect
            onChange={selectServices}
            options={services}
            getOptionLabel={(option) => option.displayName}
            getOptionValue={(option) => option.name}
            isMulti={false}
            //allowSelectAll={true}
            marginTop={"0px"}
            height={"33px"}
            fontSize={"13px"}
            padding={"0px"}
            zIndex={"1000"}
            placeholder="Services"
            className="okay"
            classNamePrefix="levels"
            name={"services"}
            styles={colourStyles}
            isClearable
          />
        </div>
        <div style={{ minWidth: 140, marginRight: 20, marginBottom: 0 }}>
          <HsSelect
            value={selectedStatus}
            onChange={selectStatus}
            options={status}
            getOptionLabel={(option) => option.name}
            getOptionValue={(option) => option.value}
            marginTop={"0px"}
            height={"33px"}
            fontSize={"13px"}
            padding={"0px"}
            zIndex={"1000"}
            placeholder="Status"
            className="okay"
            classNamePrefix="levels"
            name={"status"}
            isClearable
            // styles={colourStyles}
          />
        </div>
        
      </div>
      <div
      style={{
        flexDirection: "row",
        display: "flex",
        flexWrap: "wrap",
        flex: 1,
        alignItems: "center",
        marginBottom: 0,
        marginTop: 0,
        padding: "10px 30px",
      }}>
        <div style={{ marginRight: 20, marginBottom: 0 }}>
          <p style={{ fontWeight: "300", fontSize: 14, color: "#959cb6" }}>
          Last referral date :{" "}
          </p>
        </div>
        <div
          style={{
            minWidth: 140,
            marginRight: 20,
            marginBottom: 0,
            zoom: 0.8,
          }}
        >
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              autoOk
              label={"De "}
              disableToolbar
              variant="inline"
              inputVariant="outlined"
              format="dd/MM/yyyy"
              margin="normal"
              name="fromDate"
              id="fromDate"
              value={fromDate}
              maxDate={toDate}
              onChange={
                (value) => {
                  //console.log(value.toLocaleDateString('en-GB'))
                if (!!value && value.getDate()) 
                  {
                    let fDate = new Date(
                      value.getFullYear(),
                      value.getMonth(),
                      value.getDate(),
                      0,
                      0,
                      0
                      ).toISOString()
                  //if(fDate <= toDate)
                    setFromDate(
                      value.toISOString()
                    );
                
                }
              }}
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
            />
          </MuiPickersUtilsProvider>
        </div>
        <div
          style={{
            minWidth: 140,
            marginRight: 20,
            marginBottom: 0,
            zoom: 0.8,
          }}
        >
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              autoOk
              label={"A"}
              disableToolbar
              variant="inline"
              inputVariant="outlined"
              format="dd/MM/yyyy"
              margin="normal"
              name="toDate"
              id="toDate"
              minDate={fromDate}
              value={toDate}
              
              
              onChange={(value) => {
                if (!!value && value.getDate()) {
                  let tDate = new Date(
                    value.getFullYear(),
                    value.getMonth(),
                    value.getDate(),
                    0,
                    0,
                    0
                    ).toISOString()
                //if(tDate >= fromDate)
                  setToDate(
                    value.toISOString()
                  );
                }
              }}
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
            />
          </MuiPickersUtilsProvider>
        </div>
      </div>
    </>
  );
};

export default TableHeader;
