import React, { useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
// import MenuItem from "@material-ui/core/MenuItem";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import DialogTitle from "@material-ui/core/DialogTitle";
import Switch from "@material-ui/core/Switch";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import { Formik } from "formik";
import PropTypes from "prop-types";
import validationSchema from "./validationSchema";
import useStyles from "./styles";
// import { fetchServices } from "../../ParrainsListPage/tableHeader/fetchData";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { saveCountry } from "../actions";
import messages from "./messages";
import { countries } from "../../../../utils/countries";
import vars from "../../../../environment";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from '@material-ui/icons/Close';
const propTypes = {
  openAddCountryDialog: PropTypes.bool.isRequired,
  handleCloseAddCountryDialog: PropTypes.func.isRequired,
  localeLang: PropTypes.string.isRequired,
};

const Alert = (props) => {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
};
const AddCountryDialog = ({
  openAddCountryDialog,
  handleCloseAddCountryDialog,
  localeLang,
}) => {
  const defaultProps = {
    options: countries,
    getOptionLabel: (option) => option.name,
  };
  const [openNotification, setOpenNotification] = useState(false);
  const [countrySavedSuccess, setCountrySavedSuccess] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const classes = useStyles();

  const handleSaveClick = (values) => {
    console.log("%c⧭ values to be saved", "color: #cc7033", values);
    saveCountry(values)
      .then((response) => {
        if (response.status === 201) {
          setErrorMessage("");
          setOpenNotification(true);
          setCountrySavedSuccess(true);
        }
        setTimeout(() => {
          setOpenNotification(false);
        }, 3000);

        setTimeout(() => {
          handleCloseAddCountryDialog();
        }, 1000);
      })
      .catch((error) => {
        setCountrySavedSuccess(false);
        setOpenNotification(true);
        console.error("%c⧭", "color: #364cd9", error);
        setErrorMessage(error.response.data);
        setTimeout(() => {
          setOpenNotification(false);
        }, 3000);
      });
  };

  const initialValues = {
    name: "",
    currency: "",
    code: "",
    symbol: "",
    isActivated: false,
  };
  return (
    <div>
      <Dialog
        disableEscapeKeyDown
        disableBackdropClick
        fullWidth
        maxWidth="sm"
        open={openAddCountryDialog}
        onClose={handleCloseAddCountryDialog}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Add new country
        <IconButton
          style={{ position: "absolute", top: "-5px", right: "-5px" }}
          onClick={handleCloseAddCountryDialog}
        >
          <CloseIcon />
        </IconButton>
        </DialogTitle>
        <DialogContent>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={(values) => {
              handleSaveClick(values);
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              setFieldValue,
            }) => (
              <form onSubmit={handleSubmit}>
                {console.log("%c⧭ values =>", "color: #0fff00", values)}
                {
                  // ((console.log("%c⧭ touched =>", "color: #8c0038", touched),
                  // console.log("%c⧭ errors=>", "color: #8c0038", errors)),
                  // console.log("%c⧭ values =>", "color: #8c0038", values))
                }
                {["name"].map((key, index) => {
                  return (
                    <FormControl
                      fullWidth
                      margin="dense"
                      key={`${key}_${index}`}
                    >
                      <Autocomplete
                        options={countries}
                        getOptionLabel={(option) => option[key]}
                        renderOption={(option) => (
                          <React.Fragment>
                            <span
                              style={{
                                marginRight: 15,
                                fontSize: 12,
                                fontWeight: 400,
                              }}
                            >
                              {option[key]}
                            </span>
                            <img
                              src={`${
                                vars.flagApi
                              }/${option.countryCode2.toLowerCase()}.png`}
                              style={{
                                maxHeight: 15,
                                boxShadow: "1px 1px 3px -1px #000",
                              }}
                            />
                          </React.Fragment>
                        )}
                        value={values[key]}
                        id={`controlled-demo-${key}`}
                        // blurOnSelect
                        inputValue={values[key]}
                        onChange={(event, newValue) => {
                          setFieldValue(key, newValue ? newValue[key] : "");
                          setFieldValue(
                            "currency",
                            newValue ? newValue["currency"] : ""
                          );
                          setFieldValue(
                            "code",
                            newValue ? newValue["code"] : ""
                          );
                        }}
                        onInputChange={(event, newInputValue) => {
                          !!newInputValue && setFieldValue(key, newInputValue);
                        }}
                        onBlur={(e) => {
                          handleBlur(e);
                        }}
                        name={key}
                        fullWidth
                        renderInput={(params) => {
                         
                          return (
                            <TextField
                              variant="outlined"
                              {...params}
                              name={key}
                              // value={values[key]}
                              label={
                                messages[localeLang][`AddCountry.field.${key}`]
                              }
                              error={touched[key] && Boolean(errors[key])}
                              helperText={
                                touched[key] && errors[key]
                                  ? messages[localeLang][errors[key]]
                                  : null
                              }
                            />
                          );
                        }}
                      />
                    </FormControl>
                  );
                })}
                {["currency", "code", "symbol"].map((key, index) => {
                  return (
                    <FormControl
                      fullWidth
                      margin="dense"
                      key={`${key}_${index}`}
                    >
                      <TextField
                        variant="outlined"
                        label={messages[localeLang][`AddCountry.field.${key}`]}
                        // type="number"
                        name={key}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values[key]}
                        fullWidth
                        error={touched[key] && Boolean(errors[key])}
                        helperText={
                          touched[key] && errors[key]
                            ? messages[localeLang][errors[key]]
                            : null
                        }
                      />
                    </FormControl>
                  );
                })}
                <FormControl
                  fullWidth
                  margin="dense"
                  className={classes.switch}
                >
                  <FormControlLabel
                    control={
                      <Switch
                        checked={values.isActivated}
                        value={values.isActivated}
                        name="isActivated"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        color="primary"
                        width={33}
                        height={18}
                      />
                    }
                    label={messages[localeLang]["AddCountry.field.isActivated"]}
                  />
                </FormControl>
                <DialogActions>
                  <Button
                    onClick={handleCloseAddCountryDialog}
                    color="secondary"
                  >
                    Annuler
                  </Button>

                  <Button
                    type="submit"
                    color="primary"
                    disabled={
                      Object.keys(touched).length === 0 ||
                      (Object.keys(errors).length !== 0 &&
                        Object.keys(touched).length !== 0)
                    }
                  >
                    Sauvegarder
                  </Button>
                </DialogActions>
              </form>
            )}
          </Formik>
        </DialogContent>
      </Dialog>
      <Snackbar open={openNotification} autoHideDuration={3000}>
        <Alert severity={countrySavedSuccess ? "success" : "error"}>
          {countrySavedSuccess ? (
            "Country saved successfully!!"
          ) : (
            <>
              Saving country failed
              <br />
              Please try again after doing changes on:
              <br />
              <span
                style={{
                  fontWeight: 200,
                  fontSize: 12,
                }}
              >
                {errorMessage}
              </span>
            </>
          )}
        </Alert>
      </Snackbar>
    </div>
  );
};

AddCountryDialog.propTypes = propTypes;

export default AddCountryDialog;
