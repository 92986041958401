import styled from 'styled-components';

export default styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    position: relative;
    margin: ${props => props.margin};
    padding: ${props => props.padding};
    align-items: ${props => props.alignItems};;
    &:before{
        content: '';
        width: 4px;
        height: ${props => props.hasBorder ? "50%" : "0"};
        background: #ebedf2;
        left: .8rem;
        bottom: 0;
        position: absolute;
    }
`