/**
 *
 * TextInput
 *
 */

import styled from 'styled-components/macro';

export default styled.input`
    padding: ${ props=> props.padding ? props.padding : "8.5px"};
    width: ${ props=> props.width};
    height: 21px;
    font-size: 15px;
    border-width: ${ props=> props.borderWidth ? props.borderWidth : "1px"}; 
    border-style: ${ props=> props.borderStyle ? props.borderStyle : "solid"};
    border-color: ${ props=>props.borderColor ? props.borderColor : 'hsl(0,0%,80%)'};
    background-color: ${ props=> props.bgColor ? props.bgColor : "#fff"};
    border-radius: ${ props => props.borderRadius ? props.borderRadius : "4px"};
    margin-bottom: ${ props => props.hasErr ? "0px" : props.marginBottom};
    flex: ${props => props.flex};
    margin-top: 5px;
    ::placeholder{
        color: ${ props=> props.placeHolderColor ? props.placeHolderColor : "grey"};
        opacity: 0.5;
    }
    border-color: ${ props =>  props.hasErr ? '#fd397a' : ''};
    &:focus {
      border: ${ props =>  props.hasErr ? '1px solid #fd397a' : '1px solid #8690f6'};
      outline: none;
    }
`;
