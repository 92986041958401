import { createTheme } from '@material-ui/core/styles';

import Checked from "../../../images/checked.png";

export const getMuiTheme = () =>
createTheme({
  palette: {
    primary: {
      main: "#f0f3ff",
    },
    secondary: {
      main: "#f0f3ff",
    },
  },
  overrides: {
    MuiCheckbox: {
      root: {
        "&:hover": {
          backgroundColor: "transparent",
        },
        "&.Mui-checked:hover": {
          backgroundColor: "transparent !important",
        },
        "&.Mui-checked": {
          backgroundImage: "url(" + Checked + ")",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          backgroundSize: "60%",
        },
        "& .MuiSvgIcon-root": {
          fill: "transparent",
          backgroundColor: "rgba(224, 230, 253, 0.47)",
          borderRadius: 4,
          width: 20,
          height: 20,
        },
      },
    },
    MuiTableCell: {
      root: {
        borderBottom: "none",
      },
    },
  },
});