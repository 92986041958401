import styled from 'styled-components';
export default styled.div`
  padding: 0;
  display: flex;
  flex-direction: column;
  background-image: linear-gradient(to right,#1A0244,#1A0244);
  height: 280px;

  & .sticky-header>div {
    background: linear-gradient(to right,#1A0244,#1A0244);
    box-shadow: 0px 0px 40px 0px rgba(82, 63, 105, 0.19);
    transition: 0.5s ease;
  }
  & .sticky-header a {
    color: white;/* #757575 */;
  }
  & .sticky-header {
    
  }
`;
