/**
 *
 * columns for parrainList datatable component
 * @Author Ali Bougarne
 * @CreatedAt 18/02/2021
 *
 */
import React from "react";
import moment from "moment";
// import Switch from "react-switch";
import styled from "styled-components";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";

const P = styled.p`
  color: #595d6e;
  font-size: 13px;
  font-weight: 200;
`;

export const columns = (tableSettings, test) => {
  return [
    {
      field: "account",
      title: "Referred",
      headerStyle: { minWidth: 150 },
      cellStyle: { borderBottom: "none" },
      render: (rowData) => (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <div
            style={{
              width: 50,
              height: 50,
              backgroundColor: "#f4f4f4",
              borderRadius: "50%",
              marginRight: 10,
              display: "flex",
              position: "relative",
              justifyContent: "center",
              justifyItems: "center",
              aligItems: "center",
              minWidth: "50px",
            }}
          >
            <span
              style={{
                width: 10,
                height: 10,
                position: "absolute",
                background:
                  rowData.referredAccountIs &&
                  rowData.referredAccountIs.isActivated
                    ? "green"
                    : "red",
                borderRadius: "50%",
                top: 0,
                right: 2,
                border: "2px solid white",
              }}
            ></span>
            {rowData.image ? (
              <img
                style={{ width: 50, height: 50, borderRadius: "50%" }}
                src={rowData.image}
              />
            ) : (
              <AccountCircleIcon
                fontSize="large"
                style={{ display: "flex", alignSelf: "center" }}
              />
            )}
          </div>
          <div
            style={{ display: "flex", flexDirection: "column", width: "100%" }}
          >
            <p
              style={{
                margin: 0,
                fontWeight: "500",
                color: "#595d6e",
                fontSize: 13,
              }}
            >
              {rowData.referredAccountIs && rowData.referredAccountIs.fullName}
            </p>
            {/* <span style={{ opacity: 0.6, color: '#74788d', fontSize: 12 }}>{rowData.description[this.state.selectedLang.code].text}</span> */}
            <small style={{ opacity: 0.4, fontSize: 10 }}>
              {rowData.referredAccountIs && rowData.referredAccountIs.phone}
            </small>
          </div>
        </div>
      ),
    },
    {
      field: "refCode",
      title: "Ref. code",
      headerStyle: { maxWidth: 100 },
      cellStyle: { borderBottom: "none" },
      render: (rowData) => (
        <div>
          <P>{rowData.referrerAccount && rowData.referrerAccount.refCode}</P>
        </div>
      ),
    },
    {
      field: "referrerAccount.fullName",
      title: "Referrer",
      headerStyle: { maxWidth: 100 },
      cellStyle: { borderBottom: "none" },
      render: (rowData) => (
        <div>
          <P>{rowData.referrerAccount && rowData.referrerAccount.fullName}</P>
        </div>
      ),
    },

    {
      field: "nbConsumption",
      title: "Number of Consumption",
      headerStyle: { maxWidth: 100 },
      cellStyle: { borderBottom: "none" },
      render: (rowData) => (
        <div>
          <P>{rowData.nbConsumption}</P>
        </div>
      ),
    },
    {
      field: "service",
      title: "Service",
      headerStyle: { minWidth: 150 },
      cellStyle: { borderBottom: "none" },
      render: (rowData) => (
        <div>
          <P>{rowData.service && rowData.service.displayName}</P>
        </div>
      ),
    },

    {
      field: "created_at",
      title: "Referral date",
      headerStyle: { minWidth: 150 },
      cellStyle: { borderBottom: "none" },
      render: (rowData) => (
        <div style={{ alignItems: "center" }}>
          <P style={{ marginRight: 20 }}>
            {rowData.referredAccountIs.createdAt &&
              moment(rowData.referredAccountIs.createdAt).format("DD/MM/YYYY")}
          </P>
          <P>
            {rowData.referredAccountIs.createdAt && moment(rowData.referredAccountIs.createdAt).format("hh:mm a")}
          </P>
        </div>
      ),
    },
    {
      field: "created_at",
      title: "Registration date",
      headerStyle: { minWidth: 150 },
      cellStyle: { borderBottom: "none" },
      render: (rowData) => (
        <div style={{ alignItems: "center" }}>
          <P style={{ marginRight: 20 }}>
            {rowData.updatedAt &&
              moment(rowData.updatedAt).format("DD/MM/YYYY")}
          </P>
          <P>
            {rowData.updatedAt && moment(rowData.updatedAt).format("hh:mm a")}
          </P>
        </div>
      ),
    },
    {
      field: "status",
      title: "Status",
      headerStyle: { maxWidth: 100 },
      cellStyle: { borderBottom: "none" },
      render: (rowData) => (
        <div>
          <P>{!!rowData.status && rowData.status=="inscrit"?"Registred":"Referred"}</P>
        </div>
      ),
    },
    // {
    //   field: "actions",
    //   title: "Actions",
    //   headerStyle: { minWidth: 150 },
    //   cellStyle: { borderBottom: "none" },
    //   render: (rowData) => (
    //     <div
    //       style={{
    //         flexDirection: "row",
    //         display: "flex",
    //         alignItems: "center",
    //       }}
    //     >
    //       <Switch
    //         onChange={
    //           (e) => test(e)
    //           // handleChangeStatus(rowData._id, rowData.is_active)
    //         }
    //         checked={true}
    //         onColor="#F92C78"
    //         offColor="#a1a2a3"
    //         width={35}
    //         height={20}
    //       />
    //     </div>
    //   ),
    // },
  ];
};
