import * as Yup from "yup";



export default Yup.object().shape({
  name: Yup.string()
    .min(1,"Error.AddCountry.name.min")
    .required("Error.AddCountry.name.required"),

  code: Yup.string()
    .min(3, "Error.AddCountry.code.min")
    .max(3, "Error.AddCountry.code.max")
    .required("Error.AddCountry.code.required"),

  currency: Yup.string()
    .min(1, "Error.AddCountry.currency.min")
    .max(3, "Error.AddCountry.currency.max")
    .required("Error.AddCountry.currency.required"),

  symbol: Yup.string()
    .min(1, "Error.AddCountry.symbol.min")
    .required("Error.AddCountry.symbol.required"),
});
