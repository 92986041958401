/**
 *
 * Breadcrumbs
 *
 */

import React from "react";
import { GoHome } from 'react-icons/go'

class Breadcrumbs extends React.Component {

  // constructor(props) {
  //   super(props)
  // }

  render(){

    let breadcrumbs = this.props.breads.split('/')

      return (
        <div style={{ position: 'absolute', top: -50 }}>
          <h3 style={{ margin: 0, color: 'white', fontSize: 23, marginBottom: 0, marginLeft: 10, fontWeight: '500' }} >{this.props.title}</h3>
          {/* <span style={{ color: 'rgba(255,255,255,.7)', fontSize: 15, alignItems: 'center', display: 'flex' }}>
            {/* <GoHome style={{ color: 'white', fontSize: 17, }} /> 
            {breadcrumbs.map((val, ind)=>{
              return(
                <span key={ind} style={{ display: 'flex', alignItems: 'center', fontSize: 15 }} >
                  {
                    ind>0 ?
                      <span style={{ width: 7, height: 7, borderRadius: '50%', backgroundColor: 'rgba(255,255,255,.7)', display: 'flex', marginRight: 10, marginLeft: 10 }} ></span>
                    :
                      null
                  }
                  <span>{val}</span>
                </span>
              )
            })} 
          </span> */}
        </div>
      )
  }
}

export default Breadcrumbs;
