/**
 *
 * Text
 *
 */
import styled from 'styled-components';

export default styled.span`
line-height: ${props => props.lineHeight};
color: ${props => props.active ? "#fff": "#a0a0a0"};
font-weight: ${props => props.error ? "300" : props.fontWeight};
font-size: ${props => props.fontSize};
padding: ${props => props.padding};
border-bottom: ${props => props.borderBottom};
margin: ${props => props.margin};
width: ${props => props.width};
background-color: ${props => props.active ? props.bgActiveColor : props.bgInactiveColor};
border-radius: ${props => props.borderRadius};
cursor: pointer;
`
