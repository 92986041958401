import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

export default styled(NavLink)`
  padding: 0px 20px;
  flex: 1;
  color: white;
  text-transform: capitalize;
  display: flex;
  text-decoration: none;
  font-size: 14px;
  &.active {
   // background: #41addd;
    // color: #fff;
  }
  &:hover{
    color: #F92C78;
  }
`;
