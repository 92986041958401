/**
 *
 * icons for parrainList datatable component
 * @Author Ali Bougarne
 * @CreatedAt 18/02/2021
 *
 */
import React from "react";
import {
  ArrowUpward,
  FilterList,
  Clear,
  Search,
  CloudDownload,
  FirstPage,
  LastPage,
  NavigateNext,
  NavigateBefore,
  Delete,
  ViewList,
  ChevronRight,
} from "@material-ui/icons";

export const icons = {
  SortArrow: React.forwardRef((props, ref) => (
    <ArrowUpward {...props} fontSize="small" ref={ref} />
  )),
  Filter: React.forwardRef((props, ref) => (
    <FilterList {...props} fontSize="small" ref={ref} />
  )),
  ResetSearch: React.forwardRef((props, ref) => (
    <Clear {...props} fontSize="small" ref={ref} />
  )),
  Search: React.forwardRef((props, ref) => (
    <Search {...props} fontSize="small" ref={ref} />
  )),
  Export: React.forwardRef((props, ref) => (
    <CloudDownload {...props} fontSize="small" ref={ref} />
  )),
  FirstPage: React.forwardRef((props, ref) => (
    <FirstPage {...props} fontSize="small" ref={ref} />
  )),
  LastPage: React.forwardRef((props, ref) => (
    <LastPage {...props} fontSize="small" ref={ref} />
  )),
  NextPage: React.forwardRef((props, ref) => (
    <NavigateNext {...props} fontSize="small" ref={ref} />
  )),
  PreviousPage: React.forwardRef((props, ref) => (
    <NavigateBefore {...props} fontSize="small" ref={ref} />
  )),
  Delete: React.forwardRef((props, ref) => (
    <Delete {...props} fontSize="small" ref={ref} />
  )),
  ViewColumn: React.forwardRef((props, ref) => (
    <ViewList {...props} fontSize="small" ref={ref} />
  )),
  DetailPanel: React.forwardRef((props, ref) => (
    <ChevronRight {...props} fontSize="small" ref={ref} />
  )),
};