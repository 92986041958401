import styled from 'styled-components';

import NormalImg from 'components/Img';

const Img = styled(NormalImg)`
  width: 100%;
  margin: 0 auto;
  display: block;
  &.logo {
    height: 30px;
    width: auto;
  }
  &.langFlag{
    padding: 7px;
    border-radius: 50px;
  }
`;

export default Img;
