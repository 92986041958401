/**
 *
 * HsSelect
 *
 */

import styled from 'styled-components';
import CustomSelect from './CustomSelect'
export default styled(CustomSelect)`
.levels__control{
    padding: ${props => props.padding ? props.padding : "1px 0px"};
    border-radius: 4px;
    //margin-top: 5px; 
    margin-right: ${props => props.marginRight};
    border: ${props => props.hasErr ? "1px solid #fd397a" : ""};
    box-shadow: none;
    width: ${props => props.width};
    height: ${props => props.height};
    font-size: ${props => props.fontSize};
    /* z-index: 10; */
    margin-bottom: ${props => props.marginBottom};
    &.levels__control--is-focused{
        border-color: #8690f6;
    }
}
.levels__value-container {
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow: scroll;
    max-height: 50px;
}
.levels__menu {
    box-shadow: rgba(82, 63, 105, 0.1) 0px 0px 13px 0px;
    z-index: 100;
}
.levels__multi-value {
    // background-image: linear-gradient(to right,#fad860,#fe9104);
}
.levels__placeholder {
    color: grey;
    opacity: 0.5;
}
`;
