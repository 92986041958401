/**
 *
 * columns for parrainList datatable component
 * @Author Ali Bougarne
 * @CreatedAt 18/02/2021
 *
 */
import React from "react";
import Switch from "react-switch";
import SettingsIcon from "@material-ui/icons/Settings";
import styled from "styled-components";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import { countryCodeConverter } from "../../../utils/flagCodeConverter";
import vars from "../../../environment";
import makeStyles from "./styles";

const P = styled.p`
  color: #595d6e;
  font-size: 13px;
  font-weight: 200;
`;

export const columns = (changeCountryStatus, showSettingsDialog) => {
  const classes = makeStyles();
  return [
    {
      field: "Name",
      title: "Country",
      headerStyle: { minWidth: 150 },
      cellStyle: { borderBottom: "none" },
      render: (rowData) => (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <div
            style={{
              width: 50,
              height: 50,
              backgroundColor: "#f4f4f4",
              borderRadius: "50%",
              marginRight: 10,
              display: "flex",
              position: "relative",
              justifyContent: "center",
              justifyItems: "center",
              aligItems: "center",
              minWidth: "50px",
            }}
          >
            <span
              style={{
                width: 10,
                height: 10,
                position: "absolute",
                background: rowData.isActivated ? "green" : "red",
                borderRadius: "50%",
                top: 0,
                right: 2,
                border: "2px solid white",
              }}
            ></span>
            {countryCodeConverter.filter(
              (code) => code.cca3.toLowerCase() === rowData.code.toLowerCase()
            )[0] ? (
              <div
                className="country__flag"
                style={{
                  background: `url(${
                    vars.flagApi
                  }/${countryCodeConverter
                    .filter(
                      (code) =>
                        code.cca3.toLowerCase() === rowData.code.toLowerCase()
                    )[0]
                    .cca2.toLowerCase()}.png) center center / cover`,
                    width: 50,
                    height: 50,
                    borderRadius: "50%",
                    boxShadow: "1px 2px 10px -6px #000",
                }}
              ></div>
            ) : (
              <AccountCircleIcon
                fontSize="large"
                style={{ display: "flex", alignSelf: "center" }}
              />
            )}
          </div>
          <div
            style={{ display: "flex", flexDirection: "column", width: "100%" }}
          >
            <p
              style={{
                margin: 0,
                fontWeight: "500",
                color: "#595d6e",
                fontSize: 13,
              }}
            >
              {!!rowData.name && rowData.name}
            </p>
            {/* <span style={{ opacity: 0.6, color: '#74788d', fontSize: 12 }}>{rowData.description[this.state.selectedLang.code].text}</span> */}
            <small style={{ opacity: 0.4, fontSize: 10 }}>
              {!!rowData.code && rowData.code}
            </small>
          </div>
        </div>
      ),
    },
    {
      field: "currency",
      title: "Currency",
      headerStyle: { minWidth: 100 },
      cellStyle: { borderBottom: "none" },
      render: (rowData) => (
        <div>
          <P>{!!rowData.currency && rowData.currency}</P>
        </div>
      ),
    },
    {
      field: "symbol",
      title: "Symbol",
      headerStyle: { minWidth: 100 },
      cellStyle: { borderBottom: "none" },
      render: (rowData) => (
        <div>
          <P>{!!rowData.symbol && rowData.symbol}</P>
        </div>
      ),
    },
    {
      field: "status",
      title: "Status",
      headerStyle: { minWidth: 150 },
      cellStyle: { borderBottom: "none" },
      render: (rowData) => (
        <div
          style={{
            flexDirection: "row",
            display: "flex",
            alignItems: "center",
          }}
        >
          <Switch
            onChange={(status) =>
              changeCountryStatus({ ...rowData, isActivated: status })
            }
            checked={rowData.isActivated}
            onColor="#F92C78"
            offColor="#a1a2a3"
            /* offColor="#a1a2a3" 595e6e*/
            width={33}
            height={18}
            
          />
        </div>
      ),
    },
    {
      field: "settings",
      title: "Settings",
      headerStyle: { minWidth: 150 },
      cellStyle: { borderBottom: "none" },
      render: (rowData) => (
        <div
          style={{
            flexDirection: "row",
            display: "flex",
            alignItems: "center",
          }}
        >
          <SettingsIcon
            onClick={(e) => showSettingsDialog(rowData)}
            className={`${classes.icon}`}
          />
        </div>
      ),
    },
  ];
};
