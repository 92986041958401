
export const countries2 = [
 
  {
    "name": "Algeria",
    "currency": "DZD",
    "currency_name": "Algerian Dinar",
    "countryCode2": "DZ",
    "code": "DZA"
  },
  {
    "name": "Morocco",
    "currency": "MAD",
    "currency_name": "Moroccan Dirham",
    "countryCode2": "MA",
    "code": "MAR"
  },
  {
    "name": "Tunisia",
    "currency": "TND",
    "currency_name": "Tunisian Dollar",
    "countryCode2": "TN",
    "code": "TUN"
  }

]
export const countries = [
    {
      "name": "Afghanistan",
      "currency": "AFN",
      "currency_name": "Afghanistan Afghani",
      "countryCode2": "AF",
      "code": "AFG"
    },
    {
      "name": "Albania",
      "currency": "ALL",
      "currency_name": "Albanian Lek",
      "countryCode2": "AL",
      "code": "ALB"
    },
    {
      "name": "Algeria",
      "currency": "DZD",
      "currency_name": "Algerian Dinar",
      "countryCode2": "DZ",
      "code": "DZA"
    },
    {
      "name": "American Samoa",
      "currency": "USD",
      "currency_name": "US Dollar",
      "countryCode2": "AS",
      "code": "ASM"
    },
    {
      "name": "Andorra",
      "currency": "EUR",
      "currency_name": "Euro",
      "countryCode2": "AD",
      "code": "AND"
    },
    {
      "name": "Angola",
      "currency": "AOA",
      "currency_name": "Angolan Kwanza",
      "countryCode2": "AO",
      "code": "AGO"
    },
    {
      "name": "Anguilla",
      "currency": "XCD",
      "currency_name": "East Caribbean Dollar",
      "countryCode2": "AI",
      "code": "AIA"
    },
    {
      "name": "Antarctica",
      "currency": "XCD",
      "currency_name": "East Caribbean Dollar",
      "countryCode2": "AQ",
      "code": "ATA"
    },
    {
      "name": "Antigua and Barbuda",
      "currency": "XCD",
      "currency_name": "East Caribbean Dollar",
      "countryCode2": "AG",
      "code": "ATG"
    },
    {
      "name": "Argentina",
      "currency": "ARS",
      "currency_name": "Argentine Peso",
      "countryCode2": "AR",
      "code": "ARG"
    },
    {
      "name": "Armenia",
      "currency": "AMD",
      "currency_name": "Armenian Dram",
      "countryCode2": "AM",
      "code": "ARM"
    },
    {
      "name": "Aruba",
      "currency": "AWG",
      "currency_name": "Aruban Guilder",
      "countryCode2": "AW",
      "code": "ABW"
    },
    {
      "name": "Australia",
      "currency": "AUD",
      "currency_name": "Australian Dollar",
      "countryCode2": "AU",
      "code": "AUS"
    },
    {
      "name": "Austria",
      "currency": "EUR",
      "currency_name": "Euro",
      "countryCode2": "AT",
      "code": "AUT"
    },
    {
      "name": "Azerbaijan",
      "currency": "AZN",
      "currency_name": "Azerbaijan New Manat",
      "countryCode2": "AZ",
      "code": "AZE"
    },
    {
      "name": "Bahamas",
      "currency": "BSD",
      "currency_name": "Bahamian Dollar",
      "countryCode2": "BS",
      "code": "BHS"
    },
    {
      "name": "Bahrain",
      "currency": "BHD",
      "currency_name": "Bahraini Dinar",
      "countryCode2": "BH",
      "code": "BHR"
    },
    {
      "name": "Bangladesh",
      "currency": "BDT",
      "currency_name": "Bangladeshi Taka",
      "countryCode2": "BD",
      "code": "BGD"
    },
    {
      "name": "Barbados",
      "currency": "BBD",
      "currency_name": "Barbados Dollar",
      "countryCode2": "BB",
      "code": "BRB"
    },
    {
      "name": "Belarus",
      "currency": "BYR",
      "currency_name": "Belarussian Ruble",
      "countryCode2": "BY",
      "code": "BLR"
    },
    {
      "name": "Belgium",
      "currency": "EUR",
      "currency_name": "Euro",
      "countryCode2": "BE",
      "code": "BEL"
    },
    {
      "name": "Belize",
      "currency": "BZD",
      "currency_name": "Belize Dollar",
      "countryCode2": "BZ",
      "code": "BLZ"
    },
    {
      "name": "Benin",
      "currency": "XOF",
      "currency_name": "CFA Franc BCEAO",
      "countryCode2": "BJ",
      "code": "BEN"
    },
    {
      "name": "Bermuda",
      "currency": "BMD",
      "currency_name": "Bermudian Dollar",
      "countryCode2": "BM",
      "code": "BMU"
    },
    {
      "name": "Bhutan",
      "currency": "BTN",
      "currency_name": "Bhutan Ngultrum",
      "countryCode2": "BT",
      "code": "BTN"
    },
    {
      "name": "Bolivia",
      "currency": "BOB",
      "currency_name": "Boliviano",
      "countryCode2": "BO",
      "code": "BOL"
    },
    {
      "name": "Bosnia and Herzegovina",
      "currency": "BAM",
      "currency_name": "undefined",
      "countryCode2": "BA",
      "code": "BIH"
    },
    {
      "name": "Botswana",
      "currency": "BWP",
      "currency_name": "Botswana Pula",
      "countryCode2": "BW",
      "code": "BWA"
    },
    {
      "name": "Bouvet Island",
      "currency": "NOK",
      "currency_name": "Norwegian Krone",
      "countryCode2": "BV",
      "code": "BVT"
    },
    {
      "name": "Brazil",
      "currency": "BRL",
      "currency_name": "Brazilian Real",
      "countryCode2": "BR",
      "code": "BRA"
    },
    {
      "name": "British Indian Ocean Territory",
      "currency": "USD",
      "currency_name": "US Dollar",
      "countryCode2": "IO",
      "code": "IOT"
    },
    {
      "name": "Brunei",
      "currency": "BND",
      "currency_name": "undefined",
      "countryCode2": "BN",
      "code": "BRN"
    },
    {
      "name": "Bulgaria",
      "currency": "BGN",
      "currency_name": "Bulgarian Lev",
      "countryCode2": "BG",
      "code": "BGR"
    },
    {
      "name": "Burkina Faso",
      "currency": "XOF",
      "currency_name": "CFA Franc BCEAO",
      "countryCode2": "BF",
      "code": "BFA"
    },
    {
      "name": "Burundi",
      "currency": "BIF",
      "currency_name": "Burundi Franc",
      "countryCode2": "BI",
      "code": "BDI"
    },
    {
      "name": "Cambodia",
      "currency": "KHR",
      "currency_name": "Kampuchean Riel",
      "countryCode2": "KH",
      "code": "KHM"
    },
    {
      "name": "Cameroon",
      "currency": "XAF",
      "currency_name": "CFA Franc BEAC",
      "countryCode2": "CM",
      "code": "CMR"
    },
    {
      "name": "Canada",
      "currency": "CAD",
      "currency_name": "Canadian Dollar",
      "countryCode2": "CA",
      "code": "CAN"
    },
    {
      "name": "Cape Verde",
      "currency": "CVE",
      "currency_name": "Cape Verde Escudo",
      "countryCode2": "CV",
      "code": "CPV"
    },
    {
      "name": "Cayman Islands",
      "currency": "KYD",
      "currency_name": "Cayman Islands Dollar",
      "countryCode2": "KY",
      "code": "CYM"
    },
    {
      "name": "Central African Republic",
      "currency": "XAF",
      "currency_name": "CFA Franc BEAC",
      "countryCode2": "CF",
      "code": "CAF"
    },
    {
      "name": "Chad",
      "currency": "XAF",
      "currency_name": "undefined",
      "countryCode2": "TD",
      "code": "TCD"
    },
    {
      "name": "Chile",
      "currency": "CLP",
      "currency_name": "Chilean Peso",
      "countryCode2": "CL",
      "code": "CHL"
    },
    {
      "name": "China",
      "currency": "CNY",
      "currency_name": "Yuan Renminbi",
      "countryCode2": "CN",
      "code": "CHN"
    },
    {
      "name": "Christmas Island",
      "currency": "AUD",
      "currency_name": "Australian Dollar",
      "countryCode2": "CX",
      "code": "CXR"
    },
    {
      "name": "Cocos (Keeling) Islands",
      "currency": "AUD",
      "currency_name": " Australian Dollar",
      "countryCode2": "CC",
      "code": "CCK"
    },
    {
      "name": "Colombia",
      "currency": "COP",
      "currency_name": "Colombian Peso",
      "countryCode2": "CO",
      "code": "COL"
    },
    {
      "name": "Comoros",
      "currency": "KMF",
      "currency_name": "Comoros Franc",
      "countryCode2": "KM",
      "code": "COM"
    },
    {
      "name": "Congo",
      "currency": "XAF",
      "currency_name": "CFA Franc BEAC",
      "countryCode2": "CG",
      "code": "COG"
    },
    {
      "name": "Cook Islands",
      "currency": "NZD",
      "currency_name": "New Zealand Dollar",
      "countryCode2": "CK",
      "code": "COK"
    },
    {
      "name": "Costa Rica",
      "currency": "CRC",
      "currency_name": "Costa Rican Colon",
      "countryCode2": "CR",
      "code": "CRI"
    },
    {
      "name": "Croatia",
      "currency": "HRK",
      "currency_name": "Croatian Kuna",
      "countryCode2": "HR",
      "code": "HRV"
    },
    {
      "name": "Cuba",
      "currency": "CUP",
      "currency_name": "Cuban Peso",
      "countryCode2": "CU",
      "code": "CUB"
    },
    {
      "name": "Cyprus",
      "currency": "EUR",
      "currency_name": "Euro",
      "countryCode2": "CY",
      "code": "CYP"
    },
    {
      "name": "Czech Republic",
      "currency": "CZK",
      "currency_name": "undefined",
      "countryCode2": "CZ",
      "code": "CZE"
    },
    {
      "name": "Denmark",
      "currency": "DKK",
      "currency_name": "Danish Krone",
      "countryCode2": "DK",
      "code": "DNK"
    },
    {
      "name": "Djibouti",
      "currency": "DJF",
      "currency_name": "Djibouti Franc",
      "countryCode2": "DJ",
      "code": "DJI"
    },
    {
      "name": "Dominica",
      "currency": "XCD",
      "currency_name": "East Caribbean Dollar",
      "countryCode2": "DM",
      "code": "DMA"
    },
    {
      "name": "Dominican Republic",
      "currency": "DOP",
      "currency_name": "Dominican Peso",
      "countryCode2": "DO",
      "code": "DOM"
    },
    {
      "name": "East Timor",
      "currency": "USD",
      "currency_name": "undefined",
      "countryCode2": "TP",
      "code": "undefined"
    },
    {
      "name": "Ecuador",
      "currency": "ECS",
      "currency_name": "Ecuador Sucre",
      "countryCode2": "EC",
      "code": "ECU"
    },
    {
      "name": "Egypt",
      "currency": "EGP",
      "currency_name": "Egyptian Pound",
      "countryCode2": "EG",
      "code": "EGY"
    },
    {
      "name": "El Salvador",
      "currency": "SVC",
      "currency_name": "El Salvador Colon",
      "countryCode2": "SV",
      "code": "SLV"
    },
    {
      "name": "England",
      "currency": "GBP",
      "currency_name": "undefined",
      "countryCode2": "undefined",
      "code": "undefined"
    },
    {
      "name": "Equatorial Guinea",
      "currency": "XAF",
      "currency_name": "CFA Franc BEAC",
      "countryCode2": "GQ",
      "code": "GNQ"
    },
    {
      "name": "Eritrea",
      "currency": "ERN",
      "currency_name": "Eritrean Nakfa",
      "countryCode2": "ER",
      "code": "ERI"
    },
    {
      "name": "Estonia",
      "currency": "EUR",
      "currency_name": "Euro",
      "countryCode2": "EE",
      "code": "EST"
    },
    {
      "name": "Ethiopia",
      "currency": "ETB",
      "currency_name": "Ethiopian Birr",
      "countryCode2": "ET",
      "code": "ETH"
    },
    {
      "name": "Falkland Islands",
      "currency": "FKP",
      "currency_name": "undefined",
      "countryCode2": "FK",
      "code": "FLK"
    },
    {
      "name": "Faroe Islands",
      "currency": "DKK",
      "currency_name": " Danish Krone",
      "countryCode2": "FO",
      "code": "FRO"
    },
    {
      "name": "Fiji Islands",
      "currency": "FJD",
      "currency_name": "undefined",
      "countryCode2": "FJ",
      "code": "FJI"
    },
    {
      "name": "Finland",
      "currency": "EUR",
      "currency_name": "Euro",
      "countryCode2": "FI",
      "code": "FIN"
    },
    {
      "name": "France",
      "currency": "EUR",
      "currency_name": "Euro",
      "countryCode2": "FR",
      "code": "FRA"
    },
    {
      "name": "French Guiana",
      "currency": "EUR",
      "currency_name": "Euro",
      "countryCode2": "GF",
      "code": "GUF"
    },
    {
      "name": "French Polynesia",
      "currency": "XPF",
      "currency_name": "undefined",
      "countryCode2": "PF",
      "code": "PYF"
    },
    {
      "name": "French Southern territories",
      "currency": "EUR",
      "currency_name": "Euro",
      "countryCode2": "TF",
      "code": "ATF"
    },
    {
      "name": "Gabon",
      "currency": "XAF",
      "currency_name": "CFA Franc BEAC",
      "countryCode2": "GA",
      "code": "GAB"
    },
    {
      "name": "Gambia",
      "currency": "GMD",
      "currency_name": "Gambian Dalasi",
      "countryCode2": "GM",
      "code": "GMB"
    },
    {
      "name": "Georgia",
      "currency": "GEL",
      "currency_name": "Georgian Lari",
      "countryCode2": "GE",
      "code": "GEO"
    },
    {
      "name": "Germany",
      "currency": "EUR",
      "currency_name": "Euro",
      "countryCode2": "DE",
      "code": "DEU"
    },
    {
      "name": "Ghana",
      "currency": "GHS",
      "currency_name": "Ghanaian Cedi",
      "countryCode2": "GH",
      "code": "GHA"
    },
    {
      "name": "Gibraltar",
      "currency": "GIP",
      "currency_name": "Gibraltar Pound",
      "countryCode2": "GI",
      "code": "GIB"
    },
    {
      "name": "Greece",
      "currency": "EUR",
      "currency_name": "Euro",
      "countryCode2": "GR",
      "code": "GRC"
    },
    {
      "name": "Greenland",
      "currency": "DKK",
      "currency_name": "Danish Krone",
      "countryCode2": "GL",
      "code": "GRL"
    },
    {
      "name": "Grenada",
      "currency": "XCD",
      "currency_name": "East Carribean Dollar",
      "countryCode2": "GD",
      "code": "GRD"
    },
    {
      "name": "Guadeloupe",
      "currency": "EUR",
      "currency_name": "undefined",
      "countryCode2": "GP",
      "code": "GLP"
    },
    {
      "name": "Guam",
      "currency": "USD",
      "currency_name": "undefined",
      "countryCode2": "GU",
      "code": "GUM"
    },
    {
      "name": "Guatemala",
      "currency": "QTQ",
      "currency_name": "Guatemalan Quetzal",
      "countryCode2": "GT",
      "code": "GTM"
    },
    {
      "name": "Guinea",
      "currency": "GNF",
      "currency_name": "Guinea Franc",
      "countryCode2": "GN",
      "code": "GIN"
    },
    {
      "name": "Guinea-Bissau",
      "currency": "CFA",
      "currency_name": "undefined",
      "countryCode2": "GW",
      "code": "GNB"
    },
    {
      "name": "Guyana",
      "currency": "GYD",
      "currency_name": "Guyana Dollar",
      "countryCode2": "GY",
      "code": "GUY"
    },
    {
      "name": "Haiti",
      "currency": "HTG",
      "currency_name": "Haitian Gourde",
      "countryCode2": "HT",
      "code": "HTI"
    },
    {
      "name": "Heard Island and McDonald Islands",
      "currency": "AUD",
      "currency_name": "Australian Dollar",
      "countryCode2": "HM",
      "code": "HMD"
    },
    {
      "name": "Holy See (Vatican City State)",
      "currency": "EUR",
      "currency_name": "undefined",
      "countryCode2": "VA",
      "code": "VAT"
    },
    {
      "name": "Honduras",
      "currency": "HNL",
      "currency_name": "Honduran Lempira",
      "countryCode2": "HN",
      "code": "HND"
    },
    {
      "name": "Hong Kong",
      "currency": "HKD",
      "currency_name": "Hong Kong Dollar",
      "countryCode2": "HK",
      "code": "HKG"
    },
    {
      "name": "Hungary",
      "currency": "HUF",
      "currency_name": "Hungarian Forint",
      "countryCode2": "HU",
      "code": "HUN"
    },
    {
      "name": "Iceland",
      "currency": "ISK",
      "currency_name": "Iceland Krona",
      "countryCode2": "IS",
      "code": "ISL"
    },
    {
      "name": "India",
      "currency": "INR",
      "currency_name": "Indian Rupee",
      "countryCode2": "IN",
      "code": "IND"
    },
    {
      "name": "Indonesia",
      "currency": "IDR",
      "currency_name": "Indonesian Rupiah",
      "countryCode2": "ID",
      "code": "IDN"
    },
    {
      "name": "Iran",
      "currency": "IRR",
      "currency_name": "Iranian Rial",
      "countryCode2": "IR",
      "code": "IRN"
    },
    {
      "name": "Iraq",
      "currency": "IQD",
      "currency_name": "Iraqi Dinar",
      "countryCode2": "IQ",
      "code": "IRQ"
    },
    {
      "name": "Ireland",
      "currency": "EUR",
      "currency_name": "Euro",
      "countryCode2": "IE",
      "code": "IRL"
    },
    {
      "name": "Italy",
      "currency": "EUR",
      "currency_name": "Euro",
      "countryCode2": "IT",
      "code": "ITA"
    },
    {
      "name": "Ivory Coast",
      "currency": "XOF",
      "currency_name": "CFA Franc BCEAO",
      "countryCode2": "CI",
      "code": "CIV"
    },
    {
      "name": "Jamaica",
      "currency": "JMD",
      "currency_name": "Jamaican Dollar",
      "countryCode2": "JM",
      "code": "JAM"
    },
    {
      "name": "Japan",
      "currency": "JPY",
      "currency_name": "Japanese Yen",
      "countryCode2": "JP",
      "code": "JPN"
    },
    {
      "name": "Jordan",
      "currency": "JOD",
      "currency_name": "Jordanian Dinar",
      "countryCode2": "JO",
      "code": "JOR"
    },
    {
      "name": "Kazakstan",
      "currency": "KZT",
      "currency_name": "undefined",
      "countryCode2": "undefined",
      "code": "undefined"
    },
    {
      "name": "Kenya",
      "currency": "KES",
      "currency_name": "Kenyan Shilling",
      "countryCode2": "KE",
      "code": "KEN"
    },
    {
      "name": "Kiribati",
      "currency": "AUD",
      "currency_name": "Australian Dollar",
      "countryCode2": "KI",
      "code": "KIR"
    },
    {
      "name": "Kuwait",
      "currency": "KWD",
      "currency_name": "Kuwaiti Dinar",
      "countryCode2": "KW",
      "code": "KWT"
    },
    {
      "name": "Kyrgyzstan",
      "currency": "KGS",
      "currency_name": "Som",
      "countryCode2": "KG",
      "code": "KGZ"
    },
    {
      "name": "Laos",
      "currency": "LAK",
      "currency_name": "Lao Kip",
      "countryCode2": "LA",
      "code": "LAO"
    },
    {
      "name": "Latvia",
      "currency": "LVL",
      "currency_name": "Latvian Lats",
      "countryCode2": "LV",
      "code": "LVA"
    },
    {
      "name": "Lebanon",
      "currency": "LBP",
      "currency_name": "Lebanese Pound",
      "countryCode2": "LB",
      "code": "LBN"
    },
    {
      "name": "Lesotho",
      "currency": "LSL",
      "currency_name": "Lesotho Loti",
      "countryCode2": "LS",
      "code": "LSO"
    },
    {
      "name": "Liberia",
      "currency": "LRD",
      "currency_name": "Liberian Dollar",
      "countryCode2": "LR",
      "code": "LBR"
    },
    {
      "name": "Libyan Arab Jamahiriya",
      "currency": "LYD",
      "currency_name": "undefined",
      "countryCode2": "LY",
      "code": "LBY"
    },
    {
      "name": "Liechtenstein",
      "currency": "CHF",
      "currency_name": "Swiss Franc",
      "countryCode2": "LI",
      "code": "LIE"
    },
    {
      "name": "Lithuania",
      "currency": "LTL",
      "currency_name": "Lithuanian Litas",
      "countryCode2": "LT",
      "code": "LTU"
    },
    {
      "name": "Luxembourg",
      "currency": "EUR",
      "currency_name": "Euro",
      "countryCode2": "LU",
      "code": "LUX"
    },
    {
      "name": "Macao",
      "currency": "MOP",
      "currency_name": "undefined",
      "countryCode2": "MO",
      "code": "MAC"
    },
    {
      "name": "Macedonia",
      "currency": "MKD",
      "currency_name": "Denar",
      "countryCode2": "MK",
      "code": "MKD"
    },
    {
      "name": "Madagascar",
      "currency": "MGF",
      "currency_name": "Malagasy Franc",
      "countryCode2": "MG",
      "code": "MDG"
    },
    {
      "name": "Malawi",
      "currency": "MWK",
      "currency_name": "Malawi Kwacha",
      "countryCode2": "MW",
      "code": "MWI"
    },
    {
      "name": "Malaysia",
      "currency": "MYR",
      "currency_name": "Malaysian Ringgit",
      "countryCode2": "MY",
      "code": "MYS"
    },
    {
      "name": "Maldives",
      "currency": "MVR",
      "currency_name": "Maldive Rufiyaa",
      "countryCode2": "MV",
      "code": "MDV"
    },
    {
      "name": "Mali",
      "currency": "XOF",
      "currency_name": "CFA Franc BCEAO",
      "countryCode2": "ML",
      "code": "MLI"
    },
    {
      "name": "Malta",
      "currency": "EUR",
      "currency_name": "Euro",
      "countryCode2": "MT",
      "code": "MLT"
    },
    {
      "name": "Marshall Islands",
      "currency": "USD",
      "currency_name": "US Dollar",
      "countryCode2": "MH",
      "code": "MHL"
    },
    {
      "name": "Martinique",
      "currency": "EUR",
      "currency_name": "undefined",
      "countryCode2": "MQ",
      "code": "MTQ"
    },
    {
      "name": "Mauritania",
      "currency": "MRO",
      "currency_name": "Mauritanian Ouguiya",
      "countryCode2": "MR",
      "code": "MRT"
    },
    {
      "name": "Mauritius",
      "currency": "MUR",
      "currency_name": "Mauritius Rupee",
      "countryCode2": "MU",
      "code": "MUS"
    },
    {
      "name": "Mayotte",
      "currency": "EUR",
      "currency_name": "Euro",
      "countryCode2": "YT",
      "code": "MYT"
    },
    {
      "name": "Mexico",
      "currency": "MXN",
      "currency_name": "Mexican Nuevo Peso",
      "countryCode2": "MX",
      "code": "MEX"
    },
    {
      "name": "Micronesia, Federated States of",
      "currency": "USD",
      "currency_name": "undefined",
      "countryCode2": "undefined",
      "code": "undefined"
    },
    {
      "name": "Moldova",
      "currency": "MDL",
      "currency_name": "Moldovan Leu",
      "countryCode2": "MD",
      "code": "MDA"
    },
    {
      "name": "Monaco",
      "currency": "EUR",
      "currency_name": "undefined",
      "countryCode2": "MC",
      "code": "MCO"
    },
    {
      "name": "Mongolia",
      "currency": "MNT",
      "currency_name": "Mongolian Tugrik",
      "countryCode2": "MN",
      "code": "MNG"
    },
    {
      "name": "Montserrat",
      "currency": "XCD",
      "currency_name": "East Caribbean Dollar",
      "countryCode2": "MS",
      "code": "MSR"
    },
    {
      "name": "Morocco",
      "currency": "MAD",
      "currency_name": "Moroccan Dirham",
      "countryCode2": "MA",
      "code": "MAR"
    },
    {
      "name": "Mozambique",
      "currency": "MZN",
      "currency_name": "Mozambique Metical",
      "countryCode2": "MZ",
      "code": "MOZ"
    },
    {
      "name": "Myanmar",
      "currency": "MMR",
      "currency_name": "Myanmar Kyat",
      "countryCode2": "MM",
      "code": "MMR"
    },
    {
      "name": "Namibia",
      "currency": "NAD",
      "currency_name": "Namibian Dollar",
      "countryCode2": "NA",
      "code": "NAM"
    },
    {
      "name": "Nauru",
      "currency": "AUD",
      "currency_name": "Australian Dollar",
      "countryCode2": "NR",
      "code": "NRU"
    },
    {
      "name": "Nepal",
      "currency": "NPR",
      "currency_name": "Nepalese Rupee",
      "countryCode2": "NP",
      "code": "NPL"
    },
    {
      "name": "Netherlands",
      "currency": "EUR",
      "currency_name": "Euro",
      "countryCode2": "NL",
      "code": "NLD"
    },
    {
      "name": "Netherlands Antilles",
      "currency": "ANG",
      "currency_name": "Netherlands Antillean Guilder",
      "countryCode2": "AN",
      "code": "undefined"
    },
    {
      "name": "New Caledonia",
      "currency": "XPF",
      "currency_name": "undefined",
      "countryCode2": "NC",
      "code": "NCL"
    },
    {
      "name": "New Zealand",
      "currency": "NZD",
      "currency_name": "New Zealand Dollar",
      "countryCode2": "NZ",
      "code": "NZL"
    },
    {
      "name": "Nicaragua",
      "currency": "NIO",
      "currency_name": "Nicaraguan Cordoba Oro",
      "countryCode2": "NI",
      "code": "NIC"
    },
    {
      "name": "Niger",
      "currency": "XOF",
      "currency_name": "CFA Franc BCEAO",
      "countryCode2": "NE",
      "code": "NER"
    },
    {
      "name": "Nigeria",
      "currency": "NGN",
      "currency_name": "Nigerian Naira",
      "countryCode2": "NG",
      "code": "NGA"
    },
    {
      "name": "Niue",
      "currency": "NZD",
      "currency_name": "New Zealand Dollar",
      "countryCode2": "NU",
      "code": "NIU"
    },
    {
      "name": "Norfolk Island",
      "currency": "AUD",
      "currency_name": "Australian Dollar",
      "countryCode2": "NF",
      "code": "NFK"
    },
    {
      "name": "North Korea",
      "currency": "KPW",
      "currency_name": "North Korean Won",
      "countryCode2": "KP",
      "code": "PRK"
    },
    {
      "name": "Northern Ireland",
      "currency": "GBP",
      "currency_name": "undefined",
      "countryCode2": "undefined",
      "code": "undefined"
    },
    {
      "name": "Northern Mariana Islands",
      "currency": "USD",
      "currency_name": "US Dollar",
      "countryCode2": "MP",
      "code": "MNP"
    },
    {
      "name": "Norway",
      "currency": "NOK",
      "currency_name": "Norwegian Krone",
      "countryCode2": "NO",
      "code": "NOR"
    },
    {
      "name": "Oman",
      "currency": "OMR",
      "currency_name": "Omani Rial",
      "countryCode2": "OM",
      "code": "OMN"
    },
    {
      "name": "Pakistan",
      "currency": "PKR",
      "currency_name": "Pakistan Rupee",
      "countryCode2": "PK",
      "code": "PAK"
    },
    {
      "name": "Palau",
      "currency": "USD",
      "currency_name": "US Dollar",
      "countryCode2": "PW",
      "code": "PLW"
    },
    {
      "name": "Palestine",
      "currency": "JOD",
      "currency_name": "Jordanian Dinar",
      "countryCode2": "PS",
      "code": "PSE"
    },
    {
      "name": "Panama",
      "currency": "PAB",
      "currency_name": "Panamanian Balboa",
      "countryCode2": "PA",
      "code": "PAN"
    },
    {
      "name": "Papua New Guinea",
      "currency": "PGK",
      "currency_name": "Papua New Guinea Kina",
      "countryCode2": "PG",
      "code": "PNG"
    },
    {
      "name": "Paraguay",
      "currency": "PYG",
      "currency_name": "Paraguay Guarani",
      "countryCode2": "PY",
      "code": "PRY"
    },
    {
      "name": "Peru",
      "currency": "PEN",
      "currency_name": "Peruvian Nuevo Sol",
      "countryCode2": "PE",
      "code": "PER"
    },
    {
      "name": "Philippines",
      "currency": "PHP",
      "currency_name": "Philippine Peso",
      "countryCode2": "PH",
      "code": "PHL"
    },
    {
      "name": "Pitcairn",
      "currency": "NZD",
      "currency_name": "undefined",
      "countryCode2": "PN",
      "code": "PCN"
    },
    {
      "name": "Poland",
      "currency": "PLN",
      "currency_name": "Polish Zloty",
      "countryCode2": "PL",
      "code": "POL"
    },
    {
      "name": "Portugal",
      "currency": "EUR",
      "currency_name": "Euro",
      "countryCode2": "PT",
      "code": "PRT"
    },
    {
      "name": "Puerto Rico",
      "currency": "USD",
      "currency_name": "US Dollar",
      "countryCode2": "PR",
      "code": "PRI"
    },
    {
      "name": "Qatar",
      "currency": "QAR",
      "currency_name": "Qatari Rial",
      "countryCode2": "QA",
      "code": "QAT"
    },
    {
      "name": "Reunion",
      "currency": "EUR",
      "currency_name": "undefined",
      "countryCode2": "RE",
      "code": "REU"
    },
    {
      "name": "Romania",
      "currency": "RON",
      "currency_name": "Romanian New Leu",
      "countryCode2": "RO",
      "code": "ROU"
    },
    {
      "name": "Russian Federation",
      "currency": "RUB",
      "currency_name": "undefined",
      "countryCode2": "RU",
      "code": "RUS"
    },
    {
      "name": "Rwanda",
      "currency": "RWF",
      "currency_name": "Rwanda Franc",
      "countryCode2": "RW",
      "code": "RWA"
    },
    {
      "name": "Saint Helena",
      "currency": "SHP",
      "currency_name": "St. Helena Pound",
      "countryCode2": "SH",
      "code": "undefined"
    },
    {
      "name": "Saint Kitts and Nevis",
      "currency": "XCD",
      "currency_name": "undefined",
      "countryCode2": "KN",
      "code": "KNA"
    },
    {
      "name": "Saint Lucia",
      "currency": "XCD",
      "currency_name": "East Caribbean Dollar",
      "countryCode2": "LC",
      "code": "LCA"
    },
    {
      "name": "Saint Pierre and Miquelon",
      "currency": "EUR",
      "currency_name": " Euro",
      "countryCode2": "PM",
      "code": "SPM"
    },
    {
      "name": "Saint Vincent and the Grenadines",
      "currency": "XCD",
      "currency_name": "East Caribbean Dollar",
      "countryCode2": "VC",
      "code": "VCT"
    },
    {
      "name": "Samoa",
      "currency": "WST",
      "currency_name": "Samoan Tala",
      "countryCode2": "WS",
      "code": "WSM"
    },
    {
      "name": "San Marino",
      "currency": "EUR",
      "currency_name": "Euro",
      "countryCode2": "SM",
      "code": "SMR"
    },
    {
      "name": "Sao Tome and Principe",
      "currency": "STD",
      "currency_name": " Dobra",
      "countryCode2": "ST",
      "code": "STP"
    },
    {
      "name": "Saudi Arabia",
      "currency": "SAR",
      "currency_name": "Saudi Riyal",
      "countryCode2": "SA",
      "code": "SAU"
    },
    {
      "name": "Scotland",
      "currency": "GBP",
      "currency_name": "undefined",
      "countryCode2": "undefined",
      "code": "undefined"
    },
    {
      "name": "Senegal",
      "currency": "XOF",
      "currency_name": "CFA Franc BCEAO",
      "countryCode2": "SN",
      "code": "SEN"
    },
    {
      "name": "Seychelles",
      "currency": "SCR",
      "currency_name": "Seychelles Rupee",
      "countryCode2": "SC",
      "code": "SYC"
    },
    {
      "name": "Sierra Leone",
      "currency": "SLL",
      "currency_name": "Sierra Leone Leone",
      "countryCode2": "SL",
      "code": "SLE"
    },
    {
      "name": "Singapore",
      "currency": "SGD",
      "currency_name": "Singapore Dollar",
      "countryCode2": "SG",
      "code": "SGP"
    },
    {
      "name": "Slovakia",
      "currency": "EUR",
      "currency_name": "Euro",
      "countryCode2": "SK",
      "code": "SVK"
    },
    {
      "name": "Slovenia",
      "currency": "EUR",
      "currency_name": "Euro",
      "countryCode2": "SI",
      "code": "SVN"
    },
    {
      "name": "Solomon Islands",
      "currency": "SBD",
      "currency_name": "Solomon Islands Dollar",
      "countryCode2": "SB",
      "code": "SLB"
    },
    {
      "name": "Somalia",
      "currency": "SOS",
      "currency_name": "Somali Shilling",
      "countryCode2": "SO",
      "code": "SOM"
    },
    {
      "name": "South Africa",
      "currency": "ZAR",
      "currency_name": "South African Rand",
      "countryCode2": "ZA",
      "code": "ZAF"
    },
    {
      "name": "South Georgia and the South Sandwich Islands",
      "currency": "GBP",
      "currency_name": "Pound Sterling",
      "countryCode2": "GS",
      "code": "SGS"
    },
    {
      "name": "South Korea",
      "currency": "KRW",
      "currency_name": "Korean Won",
      "countryCode2": "KR",
      "code": "KOR"
    },
    {
      "name": "South Sudan",
      "currency": "SSP",
      "currency_name": "South Sudan Pound",
      "countryCode2": "undefined",
      "code": "undefined"
    },
    {
      "name": "Spain",
      "currency": "EUR",
      "currency_name": "Euro",
      "countryCode2": "ES",
      "code": "ESP"
    },
    {
      "name": "SriLanka",
      "currency": "LKR",
      "currency_name": "undefined",
      "countryCode2": "undefined",
      "code": "undefined"
    },
    {
      "name": "Sudan",
      "currency": "SDG",
      "currency_name": "Sudanese Pound",
      "countryCode2": "SD",
      "code": "SDN"
    },
    {
      "name": "Suriname",
      "currency": "SRD",
      "currency_name": "Surinam Dollar",
      "countryCode2": "SR",
      "code": "SUR"
    },
    {
      "name": "Svalbard and Jan Mayen",
      "currency": "NOK",
      "currency_name": "undefined",
      "countryCode2": "SJ",
      "code": "SJM"
    },
    {
      "name": "Swaziland",
      "currency": "SZL",
      "currency_name": "Swaziland Lilangeni",
      "countryCode2": "SZ",
      "code": "SWZ"
    },
    {
      "name": "Sweden",
      "currency": "SEK",
      "currency_name": "Swedish Krona",
      "countryCode2": "SE",
      "code": "SWE"
    },
    {
      "name": "Switzerland",
      "currency": "CHF",
      "currency_name": "Swiss Franc",
      "countryCode2": "CH",
      "code": "CHE"
    },
    {
      "name": "Syria",
      "currency": "SYP",
      "currency_name": "Syrian Pound",
      "countryCode2": "SY",
      "code": "SYR"
    },
    {
      "name": "Tajikistan",
      "currency": "TJS",
      "currency_name": "Tajik Somoni",
      "countryCode2": "TJ",
      "code": "TJK"
    },
    {
      "name": "Tanzania",
      "currency": "TZS",
      "currency_name": "Tanzanian Shilling",
      "countryCode2": "TZ",
      "code": "TZA"
    },
    {
      "name": "Thailand",
      "currency": "THB",
      "currency_name": "Thai Baht",
      "countryCode2": "TH",
      "code": "THA"
    },
    {
      "name": "The Democratic Republic of Congo",
      "currency": "CDF",
      "currency_name": "undefined",
      "countryCode2": "undefined",
      "code": "undefined"
    },
    {
      "name": "Togo",
      "currency": "XOF",
      "currency_name": "CFA Franc BCEAO",
      "countryCode2": "TG",
      "code": "TGO"
    },
    {
      "name": "Tokelau",
      "currency": "NZD",
      "currency_name": "New Zealand Dollar",
      "countryCode2": "TK",
      "code": "TKL"
    },
    {
      "name": "Tonga",
      "currency": "TOP",
      "currency_name": "Tongan Pa'anga",
      "countryCode2": "TO",
      "code": "TON"
    },
    {
      "name": "Trinidad and Tobago",
      "currency": "TTD",
      "currency_name": "Trinidad and Tobago Dollar",
      "countryCode2": "TT",
      "code": "TTO"
    },
    {
      "name": "Tunisia",
      "currency": "TND",
      "currency_name": "Tunisian Dollar",
      "countryCode2": "TN",
      "code": "TUN"
    },
    {
      "name": "Turkey",
      "currency": "TRY",
      "currency_name": "Turkish Lira",
      "countryCode2": "TR",
      "code": "TUR"
    },
    {
      "name": "Turkmenistan",
      "currency": "TMT",
      "currency_name": "Manat",
      "countryCode2": "TM",
      "code": "TKM"
    },
    {
      "name": "Turks and Caicos Islands",
      "currency": "USD",
      "currency_name": "undefined",
      "countryCode2": "TC",
      "code": "TCA"
    },
    {
      "name": "Tuvalu",
      "currency": "AUD",
      "currency_name": "Australian Dollar",
      "countryCode2": "TV",
      "code": "TUV"
    },
    {
      "name": "Uganda",
      "currency": "UGX",
      "currency_name": "Uganda Shilling",
      "countryCode2": "UG",
      "code": "UGA"
    },
    {
      "name": "Ukraine",
      "currency": "UAH",
      "currency_name": "Ukraine Hryvnia",
      "countryCode2": "UA",
      "code": "UKR"
    },
    {
      "name": "United Arab Emirates",
      "currency": "AED",
      "currency_name": "Arab Emirates Dirham",
      "countryCode2": "AE",
      "code": "ARE"
    },
    {
      "name": "United Kingdom",
      "currency": "GBP",
      "currency_name": "undefined",
      "countryCode2": "GB",
      "code": "GBR"
    },
    {
      "name": "United States",
      "currency": "USD",
      "currency_name": "undefined",
      "countryCode2": "US",
      "code": "USA"
    },
    {
      "name": "United States Minor Outlying Islands",
      "currency": "USD",
      "currency_name": "undefined",
      "countryCode2": "UM",
      "code": "UMI"
    },
    {
      "name": "Uruguay",
      "currency": "UYU",
      "currency_name": "Uruguayan Peso",
      "countryCode2": "UY",
      "code": "URY"
    },
    {
      "name": "Uzbekistan",
      "currency": "UZS",
      "currency_name": "Uzbekistan Sum",
      "countryCode2": "UZ",
      "code": "UZB"
    },
    {
      "name": "Vanuatu",
      "currency": "VUV",
      "currency_name": "Vanuatu Vatu",
      "countryCode2": "VU",
      "code": "VUT"
    },
    {
      "name": "Venezuela",
      "currency": "VEF",
      "currency_name": "Venezuelan Bolivar",
      "countryCode2": "VE",
      "code": "VEN"
    },
    {
      "name": "Vietnam",
      "currency": "VND",
      "currency_name": "Vietnamese Dong",
      "countryCode2": "VN",
      "code": "VNM"
    },
    {
      "name": "Virgin Islands, British",
      "currency": "USD",
      "currency_name": "undefined",
      "countryCode2": "undefined",
      "code": "undefined"
    },
    {
      "name": "Virgin Islands, U.S.",
      "currency": "USD",
      "currency_name": "undefined",
      "countryCode2": "undefined",
      "code": "undefined"
    },
    {
      "name": "Wales",
      "currency": "GBP",
      "currency_name": "undefined",
      "countryCode2": "undefined",
      "code": "undefined"
    },
    {
      "name": "Wallis and Futuna",
      "currency": "XPF",
      "currency_name": "undefined",
      "countryCode2": "WF",
      "code": "WLF"
    },
    {
      "name": "Western Sahara",
      "currency": "MAD",
      "currency_name": "Moroccan Dirham",
      "countryCode2": "EH",
      "code": "ESH"
    },
    {
      "name": "Yemen",
      "currency": "YER",
      "currency_name": "Yemeni Rial",
      "countryCode2": "YE",
      "code": "YEM"
    },
    {
      "name": "Yugoslavia",
      "currency": "undefined",
      "currency_name": "undefined",
      "countryCode2": "YU",
      "code": "undefined"
    },
    {
      "name": "Zambia",
      "currency": "ZMW",
      "currency_name": "Zambian Kwacha",
      "countryCode2": "ZM",
      "code": "ZMB"
    },
    {
      "name": "Zimbabwe",
      "currency": "ZWD",
      "currency_name": "Zimbabwe Dollar",
      "countryCode2": "ZW",
      "code": "ZWE"
    }
  ]