export default {
  en: {
    "AddCountry.field.name": "Name",
    "AddCountry.field.code": "Code",
    "AddCountry.field.currency": "Currency code",
    "AddCountry.field.symbol": "Currency symbole",
    "AddCountry.field.isActivated": "Status",

    "Error.AddCountry.name.required": "Country name required",
    "Error.AddCountry.name.min": "Country name must contain more than one letter",


    "Error.AddCountry.code.min":
    "Country code must contain 3 letters at least",
      "Error.AddCountry.code.max":
      "Country code must contain 3 letters at max",
    "Error.AddCountry.code.required":
      "Country code required",

    "Error.AddCountry.currency.min":
      "Country currency must contain more than one letter",
      "Error.AddCountry.currency.max":
      "Country currency must contain 3 letters at max",
    "Error.AddCountry.currency.required":
      "Country currency required",

    "Error.AddCountry.symbol.min":
      "Currency symbol must contain at least one letter",
    "Error.AddCountry.symbol.required":
      "Currency symbol required",
   
  },
  fr: {
    "AddCountry.field.name": "Pays",
    "AddCountry.field.code": "Code",
    "AddCountry.field.currency": "Code de la Monnaie ",
    "AddCountry.field.symbol": "Symbole de la Monnaie ",
    "AddCountry.field.isActivated": "Statut",

    "Error.AddCountry.name.required": "Nom de pays requis",
    "Error.AddCountry.name.min": "Nom de pays doit contenir au moins une lettre",

    "Error.AddCountry.code.min":
    "Code de pays doit contenir au moins trois lettre",
      "Error.AddCountry.code.max":
      "Nom de pays doit contenir maximum trois lettre",
    "Error.AddCountry.code.required":
      "Code pays requis",

    "Error.AddCountry.currency.min":
      "Code monnaie doit etre minimum trois lettre",
      "Error.AddCountry.currency.max":
      "Code monnaie doit etre maximum trois lettre",
    "Error.AddCountry.currency.required":
      "Code monnaie requis",

    "Error.AddCountry.symbol.min":
      "Symbole de la monnaie doit contenir au moins une lettre",
    "Error.AddCountry.symbol.required":
      "Symbole de la monnaie requis",
  },
};
