import vars from "../../../environment";

const axios = require("axios");
if (process.env.REACT_APP_CONTEXT !== "local")
  axios.defaults.withCredentials = true
export const saveCountrySettings = async (payload) => {
  
  return await axios.post(`${vars.referralAPI}/settings`, payload);
};

export const saveCountry = async (payload) => {
  return await axios.post(`${vars.referralAPI}/countries`, payload);
};

export const getCountrySettingByCountryAndService = async (country,service) => {
  return await axios.post(`${vars.referralAPI}/settings/country/${country}/service/${service}`);
};

