/* eslint-disable */

import React, { useState, useRef, useEffect, useLayoutEffect } from "react";
import { ThemeProvider as MuiThemeProvider } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import "react-confirm-alert/src/react-confirm-alert.css";
import "../../CustomCss.css";
import { getMuiTheme } from "./theme";

import { TablePagination } from "@material-ui/core";
import TableHeader from "./tableHeader/TableHeader";
import MaterialTable from "material-table";

import { columns } from "./columns";
import { icons } from "./icons";

const axios = require("axios");
import vars from "../../../environment";


const dateBeforeMonth = new Date();
const today = new Date(
  dateBeforeMonth.getFullYear(),
  dateBeforeMonth.getMonth(),
  dateBeforeMonth.getDate(),
  0,
  0,
  0
  );
dateBeforeMonth.setMonth(dateBeforeMonth.getMonth() - 1);
const ReferredList = () => {
  const tableRef = useRef(null);
  const [parrains, setParrains] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [tempSearch, setTempSearch] = useState("");
  const [isRequested, setIsRequested] = useState(false);
  const [tableSettings, setTableSettings] = useState({
    search: null,
    pageNumber: 0,
    pageSize: 5,
    total: 0,
    query: "",
    selectedLang: { name: "English", code: "en" },
    selectedCountries: "",
    selectedServices: "",
    fromDate: dateBeforeMonth.toISOString(),
    toDate: (new Date()).toISOString(),
    selectedStatus: null,
    filtering: [
      {
        model: "countries",
        ids: [],
      },
    ],
    typing: false,
    typingTimeout: 0,
    reorder: false,
    rankingOrder: [],
  });

  const fetchData = (tableSettings) => {
    let data = {
      take: tableSettings.pageSize,
      skip: tableSettings.pageNumber * tableSettings.pageSize,
      // search: string,
      // status: string,
      // fromDate: string,
      // toDate: string,
    };
    const countryCode =
      tableSettings.selectedCountries;
      /* tableSettings.selectedCountries.length &&
      tableSettings.selectedCountries[0]; */
    const service = tableSettings.selectedServices;
    const serviceName = tableSettings.selectedServices;
      /* tableSettings.selectedServices.length &&
      tableSettings.selectedServices[0]; */

      const fromDate = tableSettings.fromDate.substr(0,10);
      const toDate = tableSettings.toDate.substr(0,10);
      const search = !!tableSettings.search && tableSettings.search;
      const status = tableSettings.selectedStatus ? tableSettings.selectedStatus.name=="Registred"?"Inscrit":"Parrainé":null;
    data = Object.assign(
      { ...data },
      countryCode && { countryCode },
      service && { service },
      serviceName && { serviceName },
      fromDate && { fromDate },
      toDate && { toDate },
      status && { status },
      search && { search }
    );
    console.log("%c⧭ data ===> ", "color: #f200e2", data);
    if (process.env.REACT_APP_CONTEXT !== "local")
      axios.defaults.withCredentials = true
    axios
      .post(`${vars.referralAPI}/accounts/referreds`, data)
      .then((response) => {
        console.log("%c⧭ /acoounts/referreds ==> ", "color: #ff0000", response);
        setTotalRecords(
          response.data && response.data.count ? response.data.count : 0
        );
        setParrains(response.data && response.data.list);
        setTimeout(()=>{ setIsRequested(false) }, 500)

      })
      .catch((error) => {
        console.error(
          "%c⧭ error loading referrals ==>  ",
          "color: #00e600",
          error
        );

        setTimeout(()=>{ setIsRequested(false) }, 500)

      });
  };

  // const _handleChangeInputSearch = (event) => {
  //   if (tableSettings.typingTimeout) {
  //     clearTimeout(tableSettings.typingTimeout);
  //   }

  //   setTableSettings({
  //     ...tableSettings,
  //     [event.target.name]: event.target.value,
  //     typing: false,
  //     // typingTimeout: setTimeout(function () {
  //     //   tableRef.current.onQueryChange();
  //     // }, 1000),
  //   });
  // };

  useEffect(() => {
    if(!isRequested)
    {
      setIsRequested(true)
      fetchData(tableSettings);
    }
  }, [tableSettings]);

  const test = (event) => {
    console.log("%c⧭ fire ", "color: #0088cc", event);
  };

  const selectCountries = (countries) => {
    setTableSettings({
      ...tableSettings,
      pageNumber: 0,
      selectedCountries:
        countries 
          ? countries.code
          : "",
      /* selectedCountries:
        countries && countries.length
          ? countries.map((country) => country.code)
          : [], */
    });
  };

  const selectServices = (services) => {
    setTableSettings({
      ...tableSettings,
      pageNumber: 0,
      selectedServices:
        services 
          ? services.name
          : "",
      /* selectedServices:
        services && services.length
          ? services.map((service) => service.name)
          : [], */
    });
  };

  const setFilterDates = (from, to) => {
    setTableSettings({
      ...tableSettings,
      fromDate: from,
      toDate: to,
    });
  };

  const _handleKeyDownSearch = (e) => {
    if (e.key === "Enter") {
      setTableSettings({
        ...tableSettings,
        pageNumber: 0,
        search: !!tempSearch && tempSearch,
      });
    }
  };

  const _handleChangeInputSearch = (e) => {
    console.log('%c⧭', 'color: #00258c', e);
    setTempSearch(e.target.value);
  };

  const clearSearch = () => {
    setTempSearch("");
    setTableSettings({
      ...tableSettings,
      pageNumber: 0,
      search: null,
    });
  };

  return (
    <div style={{ flexDirection: "column" }}>
      <TableHeader
        title="Reffered list"
        totalRecords={totalRecords}
        data={parrains}
        addNewLink="/levels"
        reOrder={() =>
          setTableSettings({
            ...tableSettings,
            pageNumber: 0,
            reorder: true,
          })
        }
        _handleKeyDownSearch={_handleKeyDownSearch}
        clearSearch={clearSearch}
        _handleChangeInputSearch={_handleChangeInputSearch}
        selectedStatus={tableSettings.selectedStatus}
        selectStatus={(value) =>
          setTableSettings(
            {...tableSettings,
              selectedStatus: value,
            },
            () => {
              //tableRef.current.onQueryChange();
            }
          )
        }
        selectLang={(value) =>
          setTableSettings({ ...tableSettings, selectedLang: value })
        }
        selectedLang={tableSettings.selectedLang}
        selectCountries={selectCountries}
        selectServices={selectServices}
        setFilterDates={setFilterDates}
      />
      <div className="datable" style={{ marginBottom: 20 }}>
        <MuiThemeProvider theme={getMuiTheme()}>
          <MaterialTable
            tableRef={tableRef}
            title=""
            style={{
              boxShadow: "none",
            }}
            components={{
              Toolbar: (props) => <div></div>,
              Pagination: (props) => (
                <TablePagination
                  {...{ ...props, count: totalRecords }}
                  onChangePage={(e, page) => {
                    setTableSettings({
                      ...tableSettings,
                      pageNumber: page,
                    });
                  }}
                  onChangeRowsPerPage={(e) => {
                    setTableSettings({
                      ...tableSettings,
                      pageNumber: 0,
                      pageSize: e.target.value,
                    });
                    tableRef.current.onChangeRowsPerPage(e);
                  }}
                  rowsPerPage={tableSettings.pageSize}
                  page={tableSettings.pageNumber}
                  SelectProps={{
                    style: {
                      // fontSize: 14,
                    },
                  }}
                />
              ),
            }}
            icons={icons}
            columns={columns(tableSettings, test)}
            options={{
              selection: false,
              filtering: false,
              sorting: false,
              search: false,
              emptyRowsWhenPaging: false,
              pageSize: tableSettings.pageSize,
              columnsButton: false,
              exportButton: false,
              actionsColumnIndex: -1,
              paginationType: "normal",
              rowStyle: (rowData) => ({
                color: "#595d6e",
                borderBottom: "1px solid #f0f3ff",
                backgroundColor: rowData.tableData.checked
                  ? "rgba(240, 243, 255, 0.47)"
                  : "",
              }),
              headerStyle: {
                zIndex: 0,
                paddingRight: 0,
                color: "#595d6e",
                fontWeight: "500",
                fontSize: 13,
                fontFamily: "inherit",
                borderBottom: "1px solid #f0f3ff",
              },
              padding: "dense",
            }}
            data={parrains}
          />
        </MuiThemeProvider>
      </div>
    </div>
  );
};
export default withRouter(ReferredList);
