import React from "react";
import { useState, useEffect } from 'react';
import NavBar from "./NavBar";
import A from "./A";
import TopNav from "./TopNav";
import RoundBtnsContainer from "./RoundBtnsContainer";
import LinksContainer from "./LinksContainer";
import Menus from "./Menus";
import Logo from "./Logo";
import HeaderLink from "./HeaderLink";
import Img from "./Img";
import LogoImg from "./Yassir-logo-2.svg";
import LogoRow from "./cropped-yassir-icon-32x32.png";
import UsaFlag from "./usaFlag.svg";
import DZA from "../../images/flags/DZA.svg";
import TN from "../../images/flags/TN.svg";
import MA from "../../images/flags/MA.svg";
import { settings } from "./AnimatedIcons";
import { makeStyles } from "@material-ui/core/styles";
import RoundBtns from "./RoundBtns";
import { NavLink,Link } from "react-router-dom";
import Avatar from "react-avatar";
import { FiSettings, FiChevronRight, FiUsers } from "react-icons/fi";
import { TiContacts } from "react-icons/ti";
import { IoMdTimer } from "react-icons/io";
import { MdNotificationsNone } from "react-icons/md";
import Dropdown from "rc-dropdown";
import Menu, { Item as MenuItem } from "rc-menu";
import "./dropdowstyles.css";
import { withRouter } from "react-router-dom";
import Sticky from "react-stickynode";
import Row from 'components/Row';
import styled from "styled-components";
import { countries2 } from "../../utils/countries";
import FormControl from "@material-ui/core/FormControl";
import vars from "../../environment";
import Autocomplete from "@material-ui/lab/Autocomplete";
//import TextField from "@material-ui/core/TextField";
import { TextField } from "@material-ui/core";
import TextInput from "../../components/TextInput";
import Algeria from '../../images/flags/Algeria.svg';
 import Tunisia from '../../images/flags/Tunisia.svg';
 import Morocco from '../../images/flags/Morocco.svg';

const useStyles = makeStyles((theme) => ({
  menuText: {
    color: "#6c7293",
    paddingBottom: 5,
    paddingTop: 5,
  },
  menuDropDown: {
    boxShadow: "rgba(82, 63, 105, 0.1) 0px 0px 13px 0px",
    border: "none",
  },
  menuButton: {
    backgroundColor: "transparent",
    border: "none",
    color: "white",
    fontSize: 14,
    fontFamily: "inherit",
    marginRight: 10,
    padding: "5px 10px",
    borderRadius: 4,

    "&:hover": {
      backgroundColor: "#F92C78 !important",
    },
  },
  menuItem: {
    display: "flex",
    alignItems: "center",
  },
  item: {
    transition: "0.5s ease",
    "&:hover": {
      backgroundColor: "#f7f8fa",
    },
  },
  menuIcons: {
    borderRadius: 4,
    //
    "&:hover": {
      backgroundColor: "#F73278 !important",
    },
  },
  singleItem: {
    "&:hover": {
      backgroundColor: "#f8f8fb !important",
    },
    "&:hover span, &:hover a": {
      color: "#F92C78 !important",
    },
  },
}));
const Button = styled.button`
  border: none;
  border-radius: 4px;
  background-color: "#F92C78";
  color: white;
  padding: 6px;
  font-size: 14px;
  font-weight: 100;
  cursor: pointer;
  text-decoration: none;
  margin-bottom: 20px;
  align-items: center;
  width: 310px;
  align-self: flex-end;
  text-align: center;
`;

function Header({ props, adminName, flags, adminData }) {
  const classes = useStyles();
  const [selectedCountryFlag, setSelectedCountryFlag] = useState({
    "name": "Algeria",
    "currency": "DZD",
    "currency_name": "Algerian Dinar",
    "countryCode2": "DZ",
    "code": "DZA"
  });  
  //localStorage.removeItem('selectedCountry')
  //setTimeout(()=>{ localStorage.setItem('selectedCountry', "5d87db1af2d2a10748ddc576")}, 100)

  const [adminCountries, setAdminCountries] = useState([]);  
  var one = 0
  useEffect(()=>{
    // var ac=[]
    // for(var j = 0; j < countries2.length; j++) {
    //   var ob={};
    //   for(var i = 0; i < adminData.countries.length; i++){
    //     if(countries2[j].code==adminData.countries[i].name)
    //       {
    //         ob={
    //           ...countries2[j] ,
    //           id:adminData.countries[i]._id
    //         }
    //         ac.push(ob)
    //       }
    //   }
    // }
    //setAdminCountries(ac)
    var ac = adminData.countries.map(x => ({
      ...x,
      countryCode2: x.alpha2Code,
      id:x._id
    }))
    setAdminCountries(ac)

    if (ac.length > 0)
      {
        setSelectedCountryFlag(ac[0])
        //localStorage.removeItem('selectedCountry')
        //setTimeout(()=>{ localStorage.setItem('selectedCountry', ac[0].id)}, 200)
        localStorage.setItem('selectedCountry', ac[0].id)
      }
    console.log(adminCountries)
    console.log("adminCountries")
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);    
    if(urlParams.get('country')!=null && urlParams.get('country')!=undefined) 
    {
      const countSS = ac.filter((value) => value.countryCode2 == urlParams.get('country').toUpperCase())
      if(countSS.length > 0)
      {
        setSelectedCountryFlag(countSS[0])
        document.getElementById('selectedFlag').src = vars.flagApi+'/'+urlParams.get('country')+".png";
        //localStorage.removeItem('selectedCountry')
        //setTimeout(()=>{ localStorage.setItem('selectedCountry', countSS[0].id)}, 1000)
        localStorage.setItem('selectedCountry', countSS[0].id)
        console.log(countSS[0].id)
        console.log("countSS[0].id")
      }
      else
      {
        document.getElementById('selectedFlag').src = vars.flagApi+'/'+ac[0].countryCode2.toLowerCase()+".png";
      }
    }        
    else
    {
      document.getElementById('selectedFlag').src = vars.flagApi+'/'+ac[0].countryCode2.toLowerCase()+".png";
    }           
   
},[])
  const [stickyH, setStickyH] = React.useState(false);
  const handleStateChange = (status) => {
    if (status.status === Sticky.STATUS_FIXED) {
      setStickyH(true);
    } else {
      setStickyH(false);
    }
  };

  function onSelect({ key }) {
    //console.log(`${key} selected`);
  }

  function onVisibleChange(visible) {
    //console.log(visible);
  }
  const signOut = () => {
    let { history } = props;
    localStorage.removeItem("token");
    if (process.env.REACT_APP_CONTEXT === "local") 
        history.push("/login");
  };
  const menuOffers = (
    <Menu onSelect={onSelect} className={classes.menuDropDown}>
      <MenuItem
        className={classes.singleItem}
        style={{ alignItems: "center", display: "flex" }}
      >
        <span className="icon-list" style={{ fontSize: 15 }}></span>
        <HeaderLink
          className={classes.menuText}
          to="/alloffers"
          activeClassName="active"
        >
          All offers
        </HeaderLink>
      </MenuItem>
      <MenuItem
        className={classes.singleItem}
        key="1"
        style={{ alignItems: "center", display: "flex" }}
      >
        <span className="icon-sale" style={{ fontSize: 15 }}></span>
       {/*  <A href="/offers" style={{ textDecoration: "none",color:"#6D7392", marginLeft: 17 }}> */}
       <HeaderLink

          className={classes.menuText}
          to="/offers"
          activeClassName="active"
        >
          Add offer
      </HeaderLink>
          {/* </A> */}
      </MenuItem>
      <MenuItem
        className={classes.singleItem}
        key="2"
        style={{ alignItems: "center", display: "flex" }}
      >
        <span className="icon-art" style={{ fontSize: 17 }}></span>
        <HeaderLink
          className={classes.menuText}
          to="/categories"
          activeClassName="active"
        >
          Categories
        </HeaderLink>
      </MenuItem>
    </Menu>
  );

  const menuEarningPoints = (
    <Menu onSelect={onSelect} className={classes.menuDropDown}>
      <MenuItem
        className={classes.singleItem}
        style={{ alignItems: "center", display: "flex" }}
      >
        <span className="icon-list" style={{ fontSize: 15 }}></span>
        <HeaderLink
          className={classes.menuText}
          to="/allearningpoints"
          activeClassName="active"
        >
          All earning points
        </HeaderLink>
      </MenuItem>
      <MenuItem
        className={classes.singleItem}
        key="1"
        style={{ alignItems: "center", display: "flex" }}
      >
        <span className="icon-premium-quality" style={{ fontSize: 17 }}></span>
        <HeaderLink
          className={classes.menuText}
          to="/earningpoints"
          activeClassName="active"
        >
          Add earning points
        </HeaderLink>
      </MenuItem>
    </Menu>
  );

  const menuLevels = (
    <Menu onSelect={onSelect} className={classes.menuDropDown}>
      <MenuItem
        className={classes.singleItem}
        style={{ alignItems: "center", display: "flex" }}
      >
        <span className="icon-list" style={{ fontSize: 15 }}></span>
        <HeaderLink
          className={classes.menuText}
          to="/alllevels"
          activeClassName="active"
        >
          All Plans
        </HeaderLink>
      </MenuItem>
      <MenuItem
        className={classes.singleItem}
        key="1"
        style={{ alignItems: "center", display: "flex" }}
      >
        <span className="icon-reward" style={{ fontSize: 17 }}></span>
        <HeaderLink
          className={classes.menuText}
          to="/levels"
          activeClassName="active"
        >
          Add plan
        </HeaderLink>
      </MenuItem>
    </Menu>
  );


  const menuFaq = (
    <Menu onSelect={onSelect} className={classes.menuDropDown}>
      <MenuItem
        className={classes.singleItem}
        style={{ alignItems: "center", display: "flex" }}
      >
        <span className="icon-list" style={{ fontSize: 15 }}></span>
        <HeaderLink
          className={classes.menuText}
          to="/faq"
          activeClassName="active"
        >
          FAQ
        </HeaderLink>
      </MenuItem>
      {/* <MenuItem
        className={classes.singleItem}
        key="1"
        style={{ alignItems: "center", display: "flex" }}
      >
        <span className="icon-reward" style={{ fontSize: 17 }}></span>
        <HeaderLink
          className={classes.menuText}
          to="/levels"
          activeClassName="active"
        >
          Add level
        </HeaderLink>
      </MenuItem> */}
    </Menu>
  );

  const menuCustomers = (
    <Menu onSelect={onSelect} className={classes.menuDropDown}>
      <MenuItem
        className={classes.singleItem}
        style={{ alignItems: "center", display: "flex" }}
      >
        <span className="icon-list" style={{ fontSize: 15 }}></span>
        <HeaderLink
          className={classes.menuText}
          to="/allusers"
          activeClassName="active"
        >
          All Users
        </HeaderLink>
      </MenuItem>
      {/* <MenuItem
        className={classes.singleItem}
        key="1"
        style={{ alignItems: "center", display: "flex" }}
      >
        <span className="icon-rate" style={{ fontSize: 17 }}></span>
        <HeaderLink
          className={classes.menuText}
          to="/home"
          activeClassName="active"
        >
          Segments
        </HeaderLink>
      </MenuItem> */}
    </Menu>
  );
  const menuReferrals = (
    <Menu onSelect={onSelect} className={classes.menuDropDown}>
      <MenuItem
        className={classes.singleItem}
        style={{ alignItems: "center", display: "flex" }}
      >
        <span className="icon-sale" style={{ fontSize: 15 }}></span>
        <HeaderLink
          className={classes.menuText}
          to="/refer"
          activeClassName="active"
        >
          Rules & Rewards
        </HeaderLink>
      </MenuItem>
      {/* <MenuItem
        className={classes.singleItem}
        key="1"
        style={{ alignItems: "center", display: "flex" }}
      >
        <span className="icon-rate" style={{ fontSize: 17 }}></span>
        <HeaderLink
          className={classes.menuText}
          to="/home"
          activeClassName="active"
        >
          Segments
        </HeaderLink>
      </MenuItem> */}
    </Menu>
  );
  const menuSubscription = (
    <Menu onSelect={onSelect} className={classes.menuDropDown}>
      <MenuItem
        className={classes.singleItem}
        style={{ alignItems: "center", display: "flex" }}
      >
        <span className="icon-sale" style={{ fontSize: 15 }}></span>
        <HeaderLink
          className={classes.menuText}
          to="/subscription"
          activeClassName="active"
        >
          Rules & Rewards
        </HeaderLink>
      </MenuItem>
    </Menu>
  );
  const menuAdmins = (
    <Menu onSelect={onSelect} className={classes.menuDropDown}>
      <MenuItem
        className={classes.singleItem}
        style={{ alignItems: "center", display: "flex" }}
      >
        <span className="icon-list" style={{ fontSize: 15 }}></span>
        <HeaderLink
          className={classes.menuText}
          to="/admins"
          activeClassName="active"
        >
          Admin settings
        </HeaderLink>
      </MenuItem>
      {/* <MenuItem
        className={classes.singleItem}
        key="1"
        style={{ alignItems: "center", display: "flex" }}
      >
        <span className="icon-rate" style={{ fontSize: 17 }}></span>
        <HeaderLink
          className={classes.menuText}
          to="/home"
          activeClassName="active"
        >
          Segments
        </HeaderLink>
      </MenuItem> */}
    </Menu>
  );

  const menuRewards = (
    <Menu onSelect={onSelect} className={classes.menuDropDown}>
      <MenuItem
        className={classes.singleItem}
        style={{ alignItems: "center", display: "flex" }}
      >
        <span className="icon-list" style={{ fontSize: 15 }}></span>
        <HeaderLink
          className={classes.menuText}
          to="/allrewards"
          activeClassName="active"
        >
          All rewards
        </HeaderLink>
      </MenuItem>
      <MenuItem
        className={classes.singleItem}
        key="1"
        style={{ alignItems: "center", display: "flex" }}
      >
        <span className="icon-gift" style={{ fontSize: 17 }}></span>
        <HeaderLink
          className={classes.menuText}
          to="/rewardsvalidation"
          activeClassName="active"
        >
          Reward validation
        </HeaderLink>
      </MenuItem>
    </Menu>
  );

  const menuPartners = (
    <Menu onSelect={onSelect} className={classes.menuDropDown}>
      <MenuItem
        className={classes.singleItem}
        style={{ alignItems: "center", display: "flex" }}
      >
        <span className="icon-list" style={{ fontSize: 15 }}></span>
        <HeaderLink
          className={classes.menuText}
          to="/allpartners"
          activeClassName="active"
        >
          All partners
        </HeaderLink>
      </MenuItem>
      <MenuItem
        className={classes.singleItem}
        key="1"
        style={{ alignItems: "center", display: "flex" }}
      >
        <span className="icon-plus" style={{ fontSize: 17 }}></span>
        <HeaderLink
          className={classes.menuText}
          to="/partners"
          activeClassName="active"
        >
          Add partner
        </HeaderLink>
      </MenuItem>
    </Menu>
  );

  const menuServices = (
    <Menu onSelect={onSelect} className={classes.menuDropDown}>
      <MenuItem
        className={classes.singleItem}
        style={{ alignItems: "center", display: "flex" }}
      >
        <span className="icon-list" style={{ fontSize: 15 }}></span>
        <HeaderLink
          className={classes.menuText}
          to="/allservices"
          activeClassName="active"
        >
          All services
        </HeaderLink>
      </MenuItem>
      <MenuItem
        className={classes.singleItem}
        key="1"
        style={{ alignItems: "center", display: "flex" }}
      >
        <span className="icon-plus" style={{ fontSize: 17 }}></span>
        <HeaderLink
          className={classes.menuText}
          to="/services"
          activeClassName="active"
        >
          Add service
        </HeaderLink>
      </MenuItem>
    </Menu>
  );

  const menuReferral = (
    <Menu onSelect={onSelect} className={classes.menuDropDown}>
      <MenuItem
        className={classes.singleItem}
        style={{ alignItems: "center", display: "flex" }}
      >
        <span className="icon-list" style={{ fontSize: 15 }}></span>
        <HeaderLink
          className={classes.menuText}
          to="/referrers/list"
          activeClassName="active"
        >
          Referrers
        </HeaderLink>
      </MenuItem>
      <MenuItem
        className={classes.singleItem}
        style={{ alignItems: "center", display: "flex" }}
      >
        <span className="icon-list" style={{ fontSize: 15 }}></span>
        <HeaderLink
          className={classes.menuText}
          to="/referred/list"
          activeClassName="active"
        >
          Referred
        </HeaderLink>
      </MenuItem>

      <MenuItem
        className={classes.singleItem}
        style={{ alignItems: "center", display: "flex" }}
      >
        <span className="icon-list" style={{ fontSize: 15 }}></span>
        <HeaderLink
          className={classes.menuText}
          to="/referral/country-activation"
          activeClassName="active"
        >
          Country
        </HeaderLink>
      </MenuItem>
    </Menu>
  );
  const menuChallenge = (
    <Menu onSelect={onSelect} className={classes.menuDropDown}>
      <MenuItem
        className={classes.singleItem}
        style={{ alignItems: "center", display: "flex" }}
      >
        <span className="icon-list" style={{ fontSize: 15 }}></span>
        <HeaderLink
          className={classes.menuText}
          to="/allchallenges"
          activeClassName="active"
        >
          All Challenges
        </HeaderLink>
      </MenuItem>
      <MenuItem
        className={classes.singleItem}
        style={{ alignItems: "center", display: "flex" }}
      >
        <span className="icon-polaroid" style={{ fontSize: 15 }}></span>
        <HeaderLink
          className={classes.menuText}
          to="/challenges"
          activeClassName="active"
        >
          Add Challenge
        </HeaderLink>
      </MenuItem>    
      <MenuItem
        className={classes.singleItem}
        style={{ alignItems: "center", display: "flex" }}
      >
        <span className="icon-rate" style={{ fontSize: 15 }}></span>
        <HeaderLink
          className={classes.menuText}
          to="/driverstatus"
          activeClassName="active"
        >
          Driver Status
        </HeaderLink>
      </MenuItem>    
    </Menu>
  );

  const menuProfile = (
    <Menu
      onSelect={onSelect}
      className={classes.menuDropDown}
      style={{ width: 350, right: 0 }}
    >
      <div
        style={{
          height: 40,
          backgroundImage: "linear-gradient(to right,#6420D4,#F72B77)",
          borderRadius: 3,
          padding: 20,
          display: "flex",
          alignItems: "center",
        }}
      >
        {/* <RoundBtns style={{ borderRadius: 20, height: 40, width: 40 }}>
          <Avatar
            name={adminName}                    
            //maxInitials={2}
            size="60"
            //fgColor={stickyH ? "#959cb6" : "white"}
            color={stickyH ? "#eeeef5" : "rgba(255,255,255,.2)"}
            //textSizeRatio={3}
          />
        </RoundBtns> */}

        <p style={{ flex: 1, color: "white", fontSize: 17, margin: 0 }}>
          {adminName}
        </p>

        {/* <RoundBtns
          className={classes.menuIcons}
          style={{ borderRadius: 4, height: 20, width: 100 }}
        >
          {flags.map((val) => {
            if (val.name === "DZA") {
              return (
                <img
                  src={DZA}
                  key={val.name}
                  alt="Avatar"
                  className="langFlag"
                  style={{ borderRadius: 4, width: 20, marginRight: 2 }}
                />
              );
            } else if (val.name === "TUN") {
              return (
                <img
                  src={TN}
                  key={val.name}
                  alt="Avatar"
                  className="langFlag"
                  style={{ borderRadius: 4, width: 20, marginRight: 2 }}
                />
              );
            } else if (val.name === "MAR") {
              return (
                <img
                  src={MA}
                  key={val.name}
                  alt="Avatar"
                  className="langFlag"
                  style={{ borderRadius: 4, width: 20, marginRight: 2 }}
                />
              );
            }
          })}
        </RoundBtns> */}
      </div>
      <div style={{ backgroundColor: "white" ,alignItems: "center",textAlign: "center"}}>
        {/* <div
          className={classes.item}
          style={{
            display: "flex",
            alignItems: "center",
            borderBottom: "1px solid #f7f8fa",
            padding: "15px 10px",
          }}
        >
          <TiContacts
            style={{ color: "#1dc9b7", fontSize: 23, marginRight: 10 }}
          />
          <div style={{ flex: 1 }}>
            <p
              style={{
                margin: 0,
                color: "#595d6e",
                fontSize: 13,
                fontWeight: "400",
              }}
            >
              My profile
            </p>
            <span style={{ color: "#74788d", fontWeight: "300" }}>
              Account settings and more
            </span>
          </div>
          <FiChevronRight style={{ color: "#74788d", fontSize: 20 }} />
        </div> */}

        {/* {window.location.pathname.includes("/referr")?
             <div
             className={classes.item}
             style={{ alignItems: "center",padding: "15px 10px", flex: 1 , cursor: "pointer",textAlign: "center" }}
             onClick={() => window.location="/home"}
              >
             <p
               style={{
                 color: "#366cf3",
                 backgroundColor: "rgba(54,108,243,.1)",
                 padding: "10px",
                 margin: 0,
                 borderRadius: 4,
                 alignItems: "center",
                 textAlign: "center"
    
               }}
             >
               Toggle to Loyalty
             </p>
           </div>
            :
            <div
            className={classes.item}
            style={{ alignItems: "center",padding: "15px 10px", flex: 1 , cursor: "pointer",textAlign: "center" }}
            onClick={() => window.location="https://referral-stg.yassir.io/"}
          >
            <p
              style={{
                color: "#366cf3",
                backgroundColor: "rgba(54,108,243,.1)",
                padding: "10px",
                margin: 0,
                borderRadius: 4,
                alignItems: "center",
                textAlign: "center"
   
              }}
            >
              Toggle to Referral
            </p>
          </div>
            
        } */}
        {process.env.REACT_APP_CONTEXT === "local"?

        <div
          className={classes.item}
          style={{ padding: "15px 10px", flex: 1 , cursor: "pointer" }}
          onClick={() => signOut()}
        >
          <p
            style={{
              color: "#366cf3",
              backgroundColor: "rgba(54,108,243,.1)",
              padding: "10px",
              margin: 0,
              borderRadius: 4,
            }}
          >
            Sign out
          </p>
        </div>
        :
        <></>
        }

      </div>
    </Menu>
  );

  const menuSettings = (
    <Menu
      onSelect={onSelect}
      className={classes.menuDropDown}
      style={{ width: 350, right: 0 }}
    >
      <div
        style={{
          height: 100,
          backgroundImage: "linear-gradient(to right,#6420D4,#F72B77)",
          borderRadius: 3,
          padding: 20,
          display: "flex",
          textAlign: "center",
        }}
      >
        <p style={{ flex: 1, color: "white", fontSize: 17, margin: 0 }}>
          Settings
        </p>
      </div>

      <div style={{ backgroundColor: "white" }}>
        <div style={{ display: "flex", borderBottom: "1px solid #f7f8fa" }}>
          <NavLink
            style={{ textDecoration: "none" }}
            to={(window.location.pathname.includes('/referr') ?'#':'/params')}
            activeClassName="active"
          >
            <div
              className={classes.item}
              style={{
                textAlign: "center",
                padding: "30px 20px",
                borderRight: "1px solid #f7f8fa",
                flex: 1,
              }}
            >
              <FiSettings
                style={{ color: "#1dc9b7", fontSize: 23, marginBottom: 10 }}
              />
              <div style={{ flex: 1 }}>
                <p
                  style={{
                    margin: 0,
                    color: "#595d6e",
                    fontSize: 13,
                    fontWeight: "400",
                  }}
                >
                  General params
                </p>
                <span style={{ color: "#74788d", fontWeight: "300" }}>
                  Account settings and more
                </span>
              </div>
            </div>
          </NavLink>
          {/* <NavLink
            style={{ textDecoration: "none" }}
            to="/notifications"
            activeClassName="active"
          >
            <div
              className={classes.item}
              style={{ textAlign: "center", padding: "30px 20px", flex: 1 }}
            >
              <MdNotificationsNone
                style={{ color: "#1dc9b7", fontSize: 23, marginBottom: 10 }}
              />
              <div style={{ flex: 1 }}>
                <p
                  style={{
                    margin: 0,
                    color: "#595d6e",
                    fontSize: 13,
                    fontWeight: "400",
                  }}
                >
                  Notifications params
                </p>
                <span style={{ color: "#74788d", fontWeight: "300" }}>
                  Logs and notifications
                </span>
              </div>
            </div>
          </NavLink> */}
          
          <NavLink
            style={{ textDecoration: "none" }}
            to={(window.location.pathname.includes('/referr') ?'#':'/admins')}
            activeClassName="active"
          >
            <div
              className={classes.item}
              style={{
                textAlign: "center",
                padding: "30px 20px",
                borderRight: "1px solid #f7f8fa",
                flex: 1,
              }}
            >
              <FiUsers
                style={{ color: "#1dc9b7", fontSize: 23, marginBottom: 10 }}
              />
              <div style={{ flex: 1 }}>
                <p
                  style={{
                    margin: 0,
                    color: "#595d6e",
                    fontSize: 13,
                    fontWeight: "400",
                  }}
                >
                  Administrators
                </p>
                <span style={{ color: "#74788d", fontWeight: "300" }}>
                  Admin settings
                </span>
              </div>
            </div>
          </NavLink>
          
        </div>

        {/* <div style={{ display: "flex" }}>
          
          {/* <div className={classes.item} style={{ textAlign: 'center', padding: '30px 20px', borderRight: '1px solid #f7f8fa', flex: 1 }}>
            <FiUsers style={{ color: '#1dc9b7', fontSize: 23, marginBottom: 10 }} />
            <div style={{ flex: 1 }}>
              <p style={{ margin: 0, color: '#595d6e', fontSize: 13, fontWeight: '400' }}>Administrators</p>
              <span style={{ color: '#74788d', fontWeight: '300' }}>Account settings and more</span>
            </div>
          </div> }

          <div
            className={classes.item}
            style={{ textAlign: "center", padding: "30px 20px", flex: 1 }}
          >
            <MdNotificationsNone
              style={{ color: "#1dc9b7", fontSize: 23, marginBottom: 10 }}
            />
            <div style={{ flex: 1 }}>
              <p
                style={{
                  margin: 0,
                  color: "#595d6e",
                  fontSize: 13,
                  fontWeight: "400",
                }}
              >
                All notifications
              </p>
              <span style={{ color: "#74788d", fontWeight: "300" }}>
                Logs and notifications
              </span>
            </div>
          </div>
        </div> */}
      </div>
    </Menu>
  );

  return (window.location.pathname.includes("/referr") ?
  <>
    <div>
      <NavBar>
        <Sticky
          enabled={true}
          top={0}
          bottomBoundary={"#app"}
          activeClass="sticky-header"
          innerZ={1000}
          onStateChange={handleStateChange}
        >
          <TopNav
            style={{
              padding: "0 20px",
            }}
          >
           
           
            <Logo>
              <A href="/referrers/list">
                <Img src={LogoImg} alt="Fidelity Admin - Logo" className="logo" />
              </A>
            <p style={{ borderRadius: '50%', fontSize: 10 ,marginTop:30, marginLeft:15, color: "#FFF",}}><Img src={LogoRow} alt="Fidelity Admin - Logo" className="logo" style={{ width: "100%", height: "100%" }} /></p>
            <p style={{ fontSize: 10 ,marginTop:30, marginLeft:5, color: "#FFF"}}>Referral<br/> Dashboard</p>
            </Logo>
                
            <LinksContainer>
              <Menus>
                <Menu
                  className={classes.menuButton}
                  style={{ alignItems: "center", display: "flex" }}
                >
                  <HeaderLink
                    to="/referrers/list"
                    activeClassName="active"
                    style={{ color: stickyH ? "#6c7293" : "white" }}
                  >
                    Referrers
                  </HeaderLink>
                </Menu>
                <Menu
                  className={classes.menuButton}
                  style={{ alignItems: "center", display: "flex" }}
                >
                  <HeaderLink
                    to="/referred/list"
                    activeClassName="active"
                    style={{ color: stickyH ? "#6c7293" : "white" }}
                  >
                    Referred
                  </HeaderLink>
                </Menu>
                <Menu
                  className={classes.menuButton}
                  style={{ alignItems: "center", display: "flex" }}
                >
                  <HeaderLink
                    to="/referral/country-activation"
                    activeClassName="active"
                    style={{ color: stickyH ? "#6c7293" : "white" }}
                  >
                    Country
                  </HeaderLink>
                </Menu>
                
              </Menus>
            </LinksContainer>
            <RoundBtnsContainer>
              {/*--r <Dropdown
                trigger={["click"]}
                overlay={menuSettings}
                animation="slide-up"
                onVisibleChange={onVisibleChange}
              >
                <RoundBtns
                  className={classes.menuIcons}
                  style={{ borderRadius: 4, height: 30, width: 30, padding: 5 }}
                >
                  <FiSettings
                    style={{
                      color: stickyH ? "#959cb6" : "white",
                      fontSize: 18,
                    }}
                  />
                </RoundBtns>
              </Dropdown> */}
              {/* <RoundBtns
                className={classes.menuIcons}
                style={{ borderRadius: 4, height: 30, width: 110, padding: 5 }}
              >
               
                {flags.map((val) => {
                  if (val.name === "DZA") {
                    return (
                      <img
                        src={DZA}
                        key={val.name}
                        alt="Avatar"
                        className="langFlag"
                        style={{
                          borderRadius: 4,
                          width: "30px",
                          marginRight: "3px",
                        }}
                      />
                    );
                  }else if (val.name === "TUN") {
                    return (
                      <img
                        src={TN}
                        key={val.name}
                        alt="Avatar"
                        className="langFlag"
                        style={{
                          borderRadius: 4,
                          width: "30px",
                          marginRight: "3px",
                        }}
                      />
                    );
                  } else if (val.name === "MAR") {
                    return (
                      <img
                        src={MA}
                        key={val.name}
                        alt="Avatar"
                        className="langFlag"
                        style={{
                          borderRadius: 4,
                          width: "30px",
                          marginRight: "3px",
                        }}
                      />
                    );
                  }
                })}
               
              </RoundBtns> */}
              <FormControl
                      fullWidth
                      margin="dense"
                      /* key={`${key}_${index}`} */
                      style={{
                        color: "#FFFFFF  !important",
                        marginRight:30,
                        paddingRight:30,
                        position:"relative"
                      }}
                    >
                     
                      
                      <Autocomplete
                      style={{
                        color: "#FFFFFF !important",
                        marginRight:30,width:"200px"
                      }}
                        options={adminCountries.filter((value) => value.countryCode2 != selectedCountryFlag.countryCode2)}
                        getOptionLabel={(option) => option["name"]}
                        renderOption={(option) => (
                          <React.Fragment>
                            <span
                              style={{
                                marginRight: 15,
                                fontSize: 12,
                                fontWeight: 400
                                
                              }}
                            >
                              {option.name}
                            </span>
                            <img
                              src={`${
                                vars.flagApi
                              }/${option.countryCode2.toLowerCase()}.png`}
                              style={{
                                maxHeight: 15,
                                boxShadow: "1px 1px 3px -1px #000",
                              }}
                            />
                          </React.Fragment>
                        )}
                        //-value={values[key]}
                        //-id={`controlled-demo-${key}`}
                        // blurOnSelect
                        //-inputValue={values[key]}
                        onChange={(event, newValue) => {
                          document.location = window.location.href.split('?')[0]+"?country="+newValue.countryCode2.toLowerCase()
                        }}
                       /* onInputChange={(event, newInputValue) => {
                          !!newInputValue && setFieldValue(key, newInputValue);
                        }}
                        onBlur={(e) => {
                          handleBlur(e);
                        }}
                        name={key} */
                        fullWidth
                        renderInput={(params) => {
                          // console.log("params")
                          // console.log(params)
                          return (
                            
                            <div style={{ 
                              backgroundColor: 'white',
                              borderRadius : '5px',
                              padding:'5px',
                              position:"relative"
                              }}>
                            <TextField
                            inputProps={{ style: { fontFamily: 'Arial', color: 'white'}}}
                            style={{ hight:"25px",flex: 1, margin: '0 20px 0 0', color: 'white', position:"relative"}}
                           
                              variant="outlined"
                              {...params}
                              // name={"name"}
                              // value={selectedCountryFlag.name}
                              label={
                                selectedCountryFlag.name
                              }
                              size="small"
                             /*  error={touched[key] && Boolean(errors[key])}
                              helperText={
                                touched[key] && errors[key]
                                  ? messages[localeLang][errors[key]]
                                  : null
                              } */
                            /></div>
                          );
                        }}
                      />
                       <img 
                        id="selectedFlag" 
                        src=""
                        alt=""
                        style={{
                          position:"absolute",
                          bottom:"15px",
                          left:"130px",
                          maxHeight: 20,
                          boxShadow: "1px 1px 3px -1px #000",
                         
                        }}/>
                    </FormControl>
              <div style={{ alignItems: "center", display: "flex" }}>
                <small
                  style={{
                    color: stickyH ? "#a4aac0" : "rgba(255,255,255,.5)",
                  }}
                >
                  Hi,{" "}
                  <span
                    style={{
                      color: stickyH ? "#959cb6" : "rgba(255,255,255,.85)",
                    }}
                  >
                    {adminName}
                  </span>
                </small>
              </div>

              <Dropdown
                trigger={["click"]}
                overlay={menuProfile}
                animation="slide-up"
                onVisibleChange={onVisibleChange}
              >
                
                <RoundBtns
                  className={classes.menuIcons}
                  style={{ borderRadius: "50%", width: 80 }}
                >
                  <Avatar
                    name={adminName}                    
                    maxInitials={2}
                    size="40"
                    round={true}
                    
                    fgColor={stickyH ? "#959cb6" : "white"}
                    color={stickyH ? "#eeeef5" : "rgba(255,255,255,.2)"}
                    textSizeRatio={3}
                  />
                </RoundBtns>
              </Dropdown>
            </RoundBtnsContainer>
           
          </TopNav>
        </Sticky>
      </NavBar>
    </div>
  </>
  :
  <>
    <div>
      <NavBar>
        <Sticky
          enabled={true}
          top={0}
          bottomBoundary={"#app"}
          activeClass="sticky-header"
          innerZ={1000}
          onStateChange={handleStateChange}
        >
          <TopNav
            style={{
              padding: "0 20px",
              color: "#FFFFFF  !important"
            }}
          >
            <Logo>
              <A href={"/home"+window.location.search} >
                <Img src={LogoImg} alt="Fidelity Admin - Logo" className="logo" />
                
              </A>
              <p style={{ borderRadius: '50%', fontSize: 10 ,marginTop:30, marginLeft:15, color: "#FFF",}}><Img src={LogoRow} alt="Fidelity Admin - Logo" className="logo" style={{ width: "100%", height: "100%" }} /></p>
              <p style={{ fontSize: 10 ,marginTop:30, marginLeft:5, color: "#FFF",}}>Referral<br/> Dashboard</p>
            </Logo>
            <LinksContainer>
              <Menus>
                {/*--r <Dropdown
                  trigger={["hover"]}
                  overlay={menuOffers}
                  animation="slide-up"
                  onVisibleChange={onVisibleChange}
                >
                  <button
                    className={classes.menuButton}
                    style={{ color: stickyH ? "#6c7293" : "white" }}
                  >
                    Offers
                  </button>
                </Dropdown> */}

                {/* //C-2-10<Dropdown
                  trigger={["hover"]}
                  overlay={menuEarningPoints}
                  animation="slide-up"
                  onVisibleChange={onVisibleChange}
                >
                  <button
                    className={classes.menuButton}
                    style={{ color: stickyH ? "#6c7293" : "white" }}
                  >
                    Earning points
                  </button>
                </Dropdown> */}

                {/*--r <Dropdown
                  trigger={["hover"]}
                  overlay={menuLevels}
                  animation="slide-up"
                  onVisibleChange={onVisibleChange}
                >
                  <button
                    className={classes.menuButton}
                    style={{ color: stickyH ? "#6c7293" : "white" }}
                  >
                    Plans
                  </button>
                </Dropdown> */}
                
                <Dropdown
                  trigger={["hover"]}
                  overlay={menuFaq}
                  animation="slide-up"
                  onVisibleChange={onVisibleChange}
                >
                  <button
                    className={classes.menuButton}
                    style={{ color: stickyH ? "#6c7293" : "white" }}
                  >
                    FAQ
                  </button>
                </Dropdown>
                
                <Dropdown
                  trigger={["hover"]}
                  overlay={menuCustomers}
                  animation="slide-up"
                  onVisibleChange={onVisibleChange}
                >
                  <button
                    className={classes.menuButton}
                    style={{ color: stickyH ? "#6c7293" : "white" }}
                  >
                    Users
                  </button>
                </Dropdown>
                <Dropdown
                  trigger={["hover"]}
                  overlay={menuReferrals}
                  animation="slide-up"
                  onVisibleChange={onVisibleChange}
                >
                  <button
                    className={classes.menuButton}
                    style={{ color: stickyH ? "#6c7293" : "white" }}
                  >
                    Referrals
                  </button>
                </Dropdown>
                <Dropdown
                  trigger={["hover"]}
                  overlay={menuSubscription}
                  animation="slide-up"
                  onVisibleChange={onVisibleChange}
                >
                  <button
                    className={classes.menuButton}
                    style={{ color: stickyH ? "#6c7293" : "white" }}
                  >
                    Subscription
                  </button>
                </Dropdown>
                <Dropdown
                  trigger={["hover"]}
                  overlay={menuAdmins}
                  animation="slide-up"
                  onVisibleChange={onVisibleChange}
                >
                  <button
                    className={classes.menuButton}
                    style={{ color: stickyH ? "#6c7293" : "white" }}
                  >
                    Admins
                  </button>
                </Dropdown>

                {/* <Dropdown
                  trigger={["hover"]}
                  overlay={menuRewards}
                  animation="slide-up"
                  onVisibleChange={onVisibleChange}
                >
                  <button
                    className={classes.menuButton}
                    style={{ color: stickyH ? "#6c7293" : "white" }}
                  >
                    Rewards
                  </button>
                </Dropdown>

                <Dropdown
                  trigger={["hover"]}
                  overlay={menuPartners}
                  animation="slide-up"
                  onVisibleChange={onVisibleChange}
                >
                  <button
                    className={classes.menuButton}
                    style={{ color: stickyH ? "#6c7293" : "white" }}
                  >
                    Partners
                  </button>
                </Dropdown> */}
                {/*--r <Dropdown
                  trigger={["hover"]}
                  overlay={menuChallenge}
                  animation="slide-up"
                  onVisibleChange={onVisibleChange}
                >
                  <button
                    className={classes.menuButton}
                    style={{ color: stickyH ? "#6c7293" : "white" }}
                  >
                    Drivers
                  </button>
                </Dropdown> */}

                {/*  <Dropdown
                  trigger={['hover']}
                  overlay={menuServices}
                  animation="slide-up"
                  onVisibleChange={onVisibleChange}
                >
                  <button className={classes.menuButton} style={{ color: stickyH ? "#6c7293" : "white" }}>Services</button>
                </Dropdown> */}
              </Menus>
            </LinksContainer>
            <RoundBtnsContainer>
              {/*--r <Dropdown
                trigger={["click"]}
                overlay={menuSettings}
                animation="slide-up"
                onVisibleChange={onVisibleChange}
              >
                <RoundBtns
                  className={classes.menuIcons}
                  style={{ borderRadius: 4, height: 30, width: 30, padding: 5 }}
                >
                  <FiSettings
                    style={{
                      color: stickyH ? "#959cb6" : "white",
                      fontSize: 18,
                    }}
                  />
                </RoundBtns>
              </Dropdown> */}
              {/* <RoundBtns
                className={classes.menuIcons}
                style={{ borderRadius: 4, height: 30, width: 30, padding: 3 }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  xlink="http://www.w3.org/1999/xlink"
                  width="30px"
                  height="30px"
                  viewBox="0 0 24 24"
                  version="1.1"
                  className="kt-svg-icon"
                >
                  <g
                    stroke="none"
                    strokeWidth="1"
                    fill="none"
                    fillRule="evenodd"
                  >
                    <rect x="0" y="0" width="24" height="24"></rect>
                    <rect
                      fill={stickyH ? "#959cb6" : "white"}
                      x="4"
                      y="4"
                      width="7"
                      height="7"
                      rx="1.5"
                    ></rect>
                    <path
                      d="M5.5,13 L9.5,13 C10.3284271,13 11,13.6715729 11,14.5 L11,18.5 C11,19.3284271 10.3284271,20 9.5,20 L5.5,20 C4.67157288,20 4,19.3284271 4,18.5 L4,14.5 C4,13.6715729 4.67157288,13 5.5,13 Z M14.5,4 L18.5,4 C19.3284271,4 20,4.67157288 20,5.5 L20,9.5 C20,10.3284271 19.3284271,11 18.5,11 L14.5,11 C13.6715729,11 13,10.3284271 13,9.5 L13,5.5 C13,4.67157288 13.6715729,4 14.5,4 Z M14.5,13 L18.5,13 C19.3284271,13 20,13.6715729 20,14.5 L20,18.5 C20,19.3284271 19.3284271,20 18.5,20 L14.5,20 C13.6715729,20 13,19.3284271 13,18.5 L13,14.5 C13,13.6715729 13.6715729,13 14.5,13 Z"
                      fill={stickyH ? "#9ea4bc" : "white"}
                      opacity="0.3"
                    ></path>
                  </g>
                </svg>
              </RoundBtns> */}
              {/* <RoundBtns
                className={classes.menuIcons}
                style={{ borderRadius: 4, height: 30, width: 110, padding: 5 }}
              >
                {flags.map((val) => {
                  if (val.name === "DZA") {
                    return (
                      <img
                        src={DZA}
                        key={val.name}
                        alt="Avatar"
                        className="langFlag"
                        style={{
                          borderRadius: 4,
                          width: "30px",
                          marginRight: "3px",
                        }}
                      />
                    );
                  }else if (val.name === "TUN") {
                    return (
                      <img
                        src={TN}
                        key={val.name}
                        alt="Avatar"
                        className="langFlag"
                        style={{
                          borderRadius: 4,
                          width: "30px",
                          marginRight: "3px",
                        }}
                      />
                    );
                  } else if (val.name === "MAR") {
                    return (
                      <img
                        src={MA}
                        key={val.name}
                        alt="Avatar"
                        className="langFlag"
                        style={{
                          borderRadius: 4,
                          width: "30px",
                          marginRight: "3px",
                        }}
                      />
                    );
                  }
                })}

              </RoundBtns> */}
               
               <FormControl
                      fullWidth
                      margin="dense"
                      key={`name_0`}
                      style={{
                        color: "#FFFFFF  !important",
                        marginRight:30,
                        paddingRight:30,
                        position:"relative"
                      }}
                    >
                    
                      
                      <Autocomplete
                      style={{
                        color: "#FFFFFF !important",
                        marginRight:30,width:"200px"
                      }}
                        options={adminCountries.filter((value) => value.countryCode2 != selectedCountryFlag.countryCode2)}
                        getOptionLabel={(option) => option["name"]}
                        renderOption={(option) => (
                          <React.Fragment>
                            <span
                              style={{
                                marginRight: 15,
                                fontSize: 12,
                                fontWeight: 400
                                
                              }}
                            >
                              {option.name}
                            </span>
                            <img
                              src={`${
                                vars.flagApi
                              }/${option.countryCode2.toLowerCase()}.png`}
                              style={{
                                maxHeight: 15,
                                boxShadow: "1px 1px 3px -1px #000",
                              }}
                            />
                          </React.Fragment>
                        )}
                        //value={selectedCountryFlag.name}
                        id={`controlled-demo-name`}
                         //blurOnSelect
                        //inputValue={selectedCountryFlag?.name}
                        onChange={(event, newValue) => {
                          document.location = window.location.href.split('?')[0]+"?country="+newValue.countryCode2.toLowerCase()
                        }}
                       /* onInputChange={(event, newInputValue) => {
                        console.log("KKKKKKKKKKKKKKKK")
                        console.log(newInputValue)
                         // !!newInputValue && setSelectedCountryFlag(newInputValue);
                        }} */
                        /*
                        onBlur={(e) => {
                          handleBlur(e);
                        }}*/
                        /* name={"name"} */
                        fullWidth
                        renderInput={(params) => {
                          return (
                            
                            <div style={{ 
                              backgroundColor: 'white',
                              borderRadius : '5px',
                              padding:'5px',
                              position:"relative"
                              }}>
                            <TextField
                            inputProps={{ style: { fontFamily: 'Arial', color: 'white'}}}
                            style={{ hight:"25px",flex: 1, margin: '0 20px 0 0', color: 'white', position:"relative"}}
                           
                              variant="outlined"
                              {...params}
                              /* name={"name"} */
                               /* value={"Algeria"}  */
                              label={
                                selectedCountryFlag.name
                              }
                              size="small"
                             /*  error={touched[key] && Boolean(errors[key])}
                              helperText={
                                touched[key] && errors[key]
                                  ? messages[localeLang][errors[key]]
                                  : null
                              } */
                            /></div>
                          );
                        }}
                      />
                       <img 
                        id="selectedFlag" 
                        src=""
                        alt=""
                        style={{
                          position:"absolute",
                          bottom:"15px",
                          left:"130px",
                          maxHeight: 20,
                          boxShadow: "1px 1px 3px -1px #000",
                         
                        }}/>
                    </FormControl>
              <div style={{ alignItems: "center", display: "flex" }}>
                <small
                  style={{
                    color: stickyH ? "#a4aac0" : "rgba(255,255,255,.5)",
                  }}
                >
                  Hi,{" "}
                  <span
                    style={{
                      color: stickyH ? "#959cb6" : "rgba(255,255,255,.85)",
                    }}
                  >
                    {adminName}
                  </span>
                </small>
              </div>

              <Dropdown
                trigger={["click"]}
                overlay={menuProfile}
                animation="slide-up"
                onVisibleChange={onVisibleChange}
              >
                <RoundBtns
                  className={classes.menuIcons}
                  style={{ borderRadius: "50%", width: 80 }}
                >
                  <Avatar
                    name={adminName}                    
                    maxInitials={2}
                    size="40"
                    round={true}
                    
                    fgColor={stickyH ? "#959cb6" : "white"}
                    color={stickyH ? "#eeeef5" : "rgba(255,255,255,.2)"}
                    textSizeRatio={3}
                  />
                </RoundBtns>
              </Dropdown>
            </RoundBtnsContainer>
          </TopNav>
        </Sticky>
      </NavBar>
    </div>
  </>
  
  );
}

export default withRouter(Header);
