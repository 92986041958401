/* eslint-disable */

import React, { useState, useRef, useEffect, useLayoutEffect } from "react";
import { ThemeProvider as MuiThemeProvider } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import "react-confirm-alert/src/react-confirm-alert.css";
import "../../CustomCss.css";
import { getMuiTheme } from "./theme";

import { TablePagination } from "@material-ui/core";
import TableHeader from "./tableHeader/TableHeader";
import SettingDialog from "./settingsDialog/SettingDialog";
import MaterialTable from "material-table";

import { columns } from "./columns";
import { icons } from "./icons";

const axios = require("axios");

if (process.env.REACT_APP_CONTEXT !== "local")
  axios.defaults.withCredentials = true
import vars from "../../../environment";
import AddCountryDialog from "./addCountryDialog/AddCountryDialog";

const CountryActivationList = () => {
  const tableRef = useRef(null);
  const [isRequested, setIsRequested] = useState(false);
  const [countries, setCountries] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [tempSearch, setTempSearch] = useState("");
  const [currentCountry, setCurrentCountry] = useState({});
  const [openSettingDialog, setOpenSettingDialog] = useState(false);
  const [openAddCountryDialog, setOpenAddCountryDialog] = useState(false);
  const [localeLang, setLocalLang] = useState(localStorage.getItem("locale") || "en");
  const [tableSettings, setTableSettings] = useState({
    search: null,
    pageNumber: 0,
    pageSize: 5,
    total: 0,
    query: "",
    selectedLang: { name: "English", code: "en" },
    selectedCountries: [],
    selectedServices: [],
    fromDate: null,
    toDate: null,
    selectedStatus: null,
    filtering: [
      {
        model: "countries",
        ids: [],
      },
    ],
    typing: false,
    typingTimeout: 0,
    reorder: false,
    rankingOrder: [],
  });

  const fetchData = (tableSettings) => {
    let data = {
      take: tableSettings.pageSize,
      skip: tableSettings.pageNumber * tableSettings.pageSize,

    };

    let status =
      tableSettings.selectedStatus &&
      tableSettings.selectedStatus.name === "Active"
        ? true
        : false;
    (tableSettings.selectedStatus === null ||
      (tableSettings.selectedStatus &&
        tableSettings.selectedStatus.name === "All")) &&
      (status = null);

    const search = !!tableSettings.search && tableSettings.search;
    data = Object.assign(
      { ...data },
      search && { search },
      status !== null && { status }
    );

    axios
      .post(`${vars.referralAPI}/countries/all`, data)
      .then((response) => {
        setTotalRecords(
          response.data && response.data.count ? response.data.count : 0
        );
        setCountries(response.data && response.data.list);
        setTimeout(()=>{ setIsRequested(false) }, 200)
      })
      .catch((error) => {
        console.error(
          "%c⧭ error loading referrals ==>  ",
          "color: #00e600",
          error
        );
        setTimeout(()=>{ setIsRequested(false) }, 200)
      });
  };

  const showSettingsDialog = (country) => {
    setCurrentCountry(country);
    setOpenSettingDialog(true);
  };

  const handleCloseSettingDialog = () => {
    setOpenSettingDialog(false);
  };
  const handleCloseAddCountryDialog = () => {
    setOpenAddCountryDialog(false);
  };

  const showAddCountryDialog = () => {
    setOpenAddCountryDialog(true);
  };

  useEffect(() => {
    if(!isRequested)
    {
      setIsRequested(true)
      fetchData(tableSettings);
    }
  }, [tableSettings]);

  const changeCountryStatus = (country) => {
    axios
      .put(`${vars.referralAPI}/countries/${country._id}`, country)
      .then((response) => {
        if (response.status === 204) {
          setCountries(
            countries.map((c) => (c._id === country._id ? country : c))
          );
        }
      })
      .catch((error) => {
        console.error(
          "%c⧭ error loading countries ==>  ",
          "color: #00e600",
          error
        );
      });

    // console.log("%c⧭ fire ", "color: #0088cc", status);
  };

  const selectCountries = (countries) => {
    setTableSettings({
      ...tableSettings,
      selectedCountries:
        countries && countries.length
          ? countries.map((country) => country.code)
          : [],
    });
  };

  const selectServices = (services) => {
    setTableSettings({
      ...tableSettings,
      selectedServices:
        services && services.length
          ? services.map((service) => service.name)
          : [],
    });
  };

  // const setFilterDates = (from, to) => {
  //   setTableSettings({
  //     ...tableSettings,
  //     fromDate: from,
  //     toDate: to,
  //   });
  // };

  const _handleKeyDownSearch = (e) => {
    if (e.key === "Enter") {
      setTableSettings({
        ...tableSettings,
        pageNumber:0,
        search: !!tempSearch && tempSearch,
      });
    }
  };

  const _handleChangeInputSearch = (e) => {
    console.log("%c⧭", "color: #00258c", e);
    setTempSearch(e.target.value);
  };

  const clearSearch = () => {
    setTempSearch("");
    setTableSettings({
      ...tableSettings,
      pageNumber:0,
      search: null,
    });
  };

  return (
    <div style={{ flexDirection: "column" }}>
      <SettingDialog
        currentCountry={currentCountry}
        openSettingDialog={openSettingDialog}
        handleCloseSettingDialog={handleCloseSettingDialog}
        localeLang={localeLang}
      />
      <AddCountryDialog
        openAddCountryDialog={openAddCountryDialog}
        handleCloseAddCountryDialog={handleCloseAddCountryDialog}
        localeLang={localeLang}
      />
      <TableHeader
        title="Country list"
        totalRecords={totalRecords}
        data={countries}
        reOrder={() =>
          setTableSettings({
            ...tableSettings,
            pageNumber:0,
            reorder: true,
          })
        }
        showAddCountryDialog={showAddCountryDialog}
        _handleKeyDownSearch={_handleKeyDownSearch}
        clearSearch={clearSearch}
        _handleChangeInputSearch={_handleChangeInputSearch}
        selectedStatus={tableSettings.selectedStatus}
        selectStatus={(value) =>
          setTableSettings({
            ...tableSettings,
            pageNumber:0,
            selectedStatus: value,
          })
        }
        selectLang={(value) =>
          setTableSettings({ ...tableSettings, selectedLang: value })
        }
        selectedLang={tableSettings.selectedLang}
        selectCountries={selectCountries}
        selectServices={selectServices}
        // setFilterDates={setFilterDates}
      />
      <div className="datable" style={{ marginBottom: 20 }}>
        <MuiThemeProvider theme={getMuiTheme()}>
          <MaterialTable
            tableRef={tableRef}
            title=""
            style={{
              boxShadow: "none",
            }}
            components={{
              Toolbar: (props) => <div></div>,
              Pagination: (props) => (
                <TablePagination
                  {...{ ...props, count: totalRecords }}
                  onChangePage={(e, page) => {
                    setTableSettings({
                      ...tableSettings,
                      pageNumber: page,
                    });
                  }}
                  onChangeRowsPerPage={(e) => {
                    setTableSettings({
                      ...tableSettings,
                      pageNumber:0,
                      pageSize: e.target.value,
                    });
                    tableRef.current.onChangeRowsPerPage(e);
                  }}
                  rowsPerPage={tableSettings.pageSize}
                  page={tableSettings.pageNumber}
                  SelectProps={{
                    style: {
                      // fontSize: 14,
                    },
                  }}
                />
              ),
            }}
            icons={icons}
            columns={columns(changeCountryStatus, showSettingsDialog)}
            options={{
              selection: false,
              filtering: false,
              sorting: false,
              search: false,
              emptyRowsWhenPaging: false,
              pageSize: tableSettings.pageSize,
              columnsButton: false,
              exportButton: false,
              actionsColumnIndex: -1,
              paginationType: "normal",
              rowStyle: (rowData) => ({
                color: "#595d6e",
                borderBottom: "1px solid #f0f3ff",
                backgroundColor: rowData.tableData.checked
                  ? "rgba(240, 243, 255, 0.47)"
                  : "",
              }),
              headerStyle: {
                zIndex: 0,
                paddingRight: 0,
                color: "#595d6e",
                fontWeight: "500",
                fontSize: 13,
                fontFamily: "inherit",
                borderBottom: "1px solid #f0f3ff",
              },
              padding: "dense",
            }}
            data={countries}
          />
        </MuiThemeProvider>
      </div>
    </div>
  );
};
export default withRouter(CountryActivationList);
