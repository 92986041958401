import React from 'react';
import styled from 'styled-components';
import {FiAlertTriangle} from 'react-icons/fi'

const Container = styled.div`
display: flex;
flex-direction: column;
align-items: center;
flex-direction: column;
justify-content: center;
align-items: center;
margin: auto;
`

const Text = styled.p`
color: ${props => props.color};
font-size: ${props => props.fontSize};
margin: ${props => props.margins};
text-align: center;

`

const NotAllowed = ({textColor, fontSize, textMargins, iconSize, content, ...props}) => {
    return(
    <Container>
        <FiAlertTriangle size={iconSize} stroke="#fd397a"/>
        <Text color={textColor} fontSize={fontSize} margins={textMargins}>{content}</Text>
    </Container>
    )
}

export default NotAllowed