//http://35.198.128.239/v1/

const vars = {
  api:
    process.env.REACT_APP_CONTEXT !== "local"
      ? "/api/"
      : "http://localhost:8888/api/",
  api2:
    process.env.REACT_APP_CONTEXT !== "local"
      ? "/"
      : "http://localhost:8888/",
  referralAPI:
    process.env.REACT_APP_CONTEXT !== "local"
      ? "https://referral-stg.yassir.io/api/v1"
      : "http://localhost:8084/api/v1",
  imgApi:
    process.env.REACT_APP_CONTEXT !== "local"
      ? "/api/"
      : "http://localhost:8888/",
  translationApi: "https://api.mymemory.translated.net/get?q=",
  translationKey: "717216120d2c7e27cab2",
  context: process.env.REACT_APP_CONTEXT !== "local" ? "prod" : "dev",
  flagApi: "https://flagcdn.com/w80",
  challengesApi:
    process.env.REACT_APP_CONTEXT !== "local"
    ? "/api/v1/ext/challenges/dashboard/"
    : "http://localhost:8888/api/v1/ext/challenges/dashboard/",
};

// const api = processs.env.link?process.env.link:'http://192.168.1.94:8888/';
export default vars;
