import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Column from 'components/Column';
import Row from 'components/Row';
import { TiPhoneOutline, TiMail, TiWorldOutline, TiLocationOutline } from 'react-icons/ti';
import ReactNotification, { store } from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css';
import Bubble from 'components/Bubble'
import vars from 'environment';
import CircularProgress from "@material-ui/core/CircularProgress";
import styled from "styled-components";
import Separator from 'components/Separator';
const Button = styled.button`
  border: none;
  border-radius: 4px;
  background-color: #F92C78;
  color: white;
  padding: 10px;
  font-size: 15px;
  font-weight: 200;
  cursor: pointer;
  text-decoration: none;
  margin-bottom: 20px;
  align-items: center;
  width: 100px;
  align-self: flex-end;
  text-align: center;
`;
function EditAdmin(props){
    let { history } = props;
    const [admin, setAdmin] = useState({})
    const [permissionsToGive, setPermissionsToGive] = useState({})
    const [givenPermissions, setGivenPermissions] = useState([])
    const [groupedPermissions, setGroupedPermissions] = useState({})
    const [loadingButton, setLoadingButton] = React.useState(false);
    let adminId =  window.location.pathname.split('/')[2]
    const getAdmin = () =>{
        let body = {
            id: adminId,
            countryId : localStorage.getItem('selectedCountry')
        }
        axios.post(`${vars.api}v1/admins`, body, {headers:{Authorization : `bearer ${localStorage.getItem('token')}`}})
        .then((res) => {
            setAdmin(res.data.admin)
            setPermissionsToGive(res.data.permissions)
            groupPermission(res.data.permissions)
            let givenPermissionsTmp = res.data.admin.permissions.map(val =>
                val._id
            )
            setGivenPermissions(givenPermissionsTmp)

        })
        .catch((err)=>{
            console.log(err);
        })
    }
    useEffect(()=>{
        getAdmin()
    },[])

    const groupPermission = (permissions) =>{
        let result = permissions.reduce(function (r, a) {
            r[a.name.split('-')[0]] = r[a.name.split('-')[0]] || [];
            r[a.name.split('-')[0]].push(a);
            return r;
        }, Object.create(null));
        setGroupedPermissions(result)        
    }
    const handlePermissionClick = (id) => {
        let gpTmp = [...givenPermissions]
        let exists = givenPermissions.indexOf(id)
        if(exists !== -1){
            let index = gpTmp.indexOf(id);
            gpTmp.splice(index, 1)
            setGivenPermissions(gpTmp)

        }
        else{
            gpTmp.push(id)
            setGivenPermissions(gpTmp)
        }
    }
    const renderGrouped = ()=>{
        return (
            <Row>
                {
            Object.keys(groupedPermissions).map(function(key,index) {
                
                return (
                <>
                <Row style={{flex:1, flexBasis: "100%"}}><p>{key}: </p></Row>
                {groupedPermissions[key].map(val => {
                    return (
                        <>
                        <Bubble
                            key={val._id}
                            fontSize={'13px'}
                            borderRadius={"15px"}
                            bgActiveColor={"#F92C78"}
                            bgInactiveColor={"#e1e4ea"}
                            padding="10px"
                            margin="5px"
                            active={givenPermissions.indexOf(val._id) !== -1 ? true : false}
                            onClick={()=>handlePermissionClick(val._id)}
                        >
                            {val.name.split('-')[1]}
                        </Bubble>
                        </>
                    )  
                })}
                </>
            )
            })}

            </Row>
        )
    }

    const handleSubmit = () => {
        let body = {
            admin: adminId,
            permissions: givenPermissions
        }
        setLoadingButton(true)
        axios.post(`${vars.api}v1/admins/update`, body, {headers:{Authorization : `bearer ${localStorage.getItem('token')}`}})
        .then((res) => {
            store.addNotification({
                title: "Success!",
                message: "Admin updated successfully",
                type: "success",
                insert: "top",
                container: "top-right",
                animationIn: ["animated", "fadeIn"],
                animationOut: ["animated", "fadeOut"],
                dismiss: {
                  duration: 3000,
                  onScreen: true
                },
                onRemoval: (id, removedBy) => {
                    setLoadingButton(false)
                    history.push('/admins');
                }
              });

        })
        .catch((err)=>{
            console.log(err);
            store.addNotification({
                title: "Error!",
                message: "Something went wrong, check all fields and retry!",
                type: "danger",
                insert: "top",
                container: "top-right",
                animationIn: ["animated", "fadeIn"],
                animationOut: ["animated", "fadeOut"],
                dismiss: {
                  duration: 3000,
                  onScreen: true
                },
                onRemoval: (id, removedBy) => {
                    setLoadingButton(false)
                }
            });
        })

    }

    return(
        <>
        <ReactNotification />
        <Column>
        <Column style={{ boxShadow: '0 0 13px 0 rgba(82,63,105,.1)', backgroundColor: 'white', margin: 20, borderRadius: 4,padding: 20 }} >
        <Row>
            {/* <Column>
                <img src={admin.avatar}/>
            </Column> */}
            <Column style={{flex: "2 1 70%"}}>
            <Column>
                <small style={{ marginRight: 20, display: 'flex', alignItems: 'center', color: "#74788d" }}>
                    {admin.first_name}
                </small>
                <small style={{ marginRight: 20, display: 'flex', alignItems: 'center', color: "#74788d" }}>
                    {admin.last_name}
                </small>
            </Column>
            <Column>
                <small style={{ marginRight: 20, display: 'flex', alignItems: 'center', color: "#74788d" }}>
                    <TiMail style={{ fontSize: 20, marginRight: 3 }} /> {admin.email}
                </small>
                <small style={{ marginRight: 20, display: 'flex', alignItems: 'center', color: "#74788d" }}>
                    <TiPhoneOutline style={{ fontSize: 20, marginRight: 3 }} /> {admin.phone}
                </small>
            </Column>
            <Column>
                <small style={{ marginRight: 20, display: 'flex', alignItems: 'center', color: "#74788d" }}><b>Countries</b>: {!!admin.countries ? admin.countries.length ? admin.countries.map(val => {
                    return val.name+" "
                })
                :
                    "N/A"
                :
                    "N/A"
                }
                </small>
                
                <small style={{ marginRight: 20, display: 'flex', color: "#74788d" }}><b>Regions</b>: {!!admin.regions ? admin.regions.length ? admin.regions.map((val,idx) => {
                    return idx+1==admin.regions.length ? val.name : val.name+", "
                })
                :
                    "N/A"
                :
                    "N/A"
                }
                </small>
                
            </Column>
            </Column>
        </Row>
        <Row>
            <small style={{ marginRight: 20, display: 'flex', alignItems: 'center', color: "#74788d" }}><b>Services</b>: {!!admin.services ? admin.services.length ? admin.services.map(val => {
                return val.name+" "
            })
            :
                "N/A"
            :
                "N/A"
            }
            </small>
            {/* <small style={{ marginRight: 20, display: 'flex', alignItems: 'center', color: "#74788d" }}><b>Roles</b>: {!!admin.roles ? admin.roles.length ? admin.roles.map(val => {return val.name+" "})
            :
                "N/A"
            :
                "N/A"
            }
            </small> */}
            <small style={{ marginRight: 20, display: 'flex', alignItems: 'center', color: "#74788d" }}><b>Role</b>: {admin.role != undefined && !!admin.role  ? admin.role.name :"N/A"}
            </small>
        </Row>
        </Column>
        
        <Row style={{ boxShadow: '0 0 13px 0 rgba(82,63,105,.1)', backgroundColor: 'white', margin: 20, borderRadius: 4, padding: 20}} >
        
            <div style={{flex: 1}}>
                <Row>
                    {
                        renderGrouped()
                    }
                    <Separator width={"100%"} border={"1px solid #ebedf2"} margin={"20px 0 20px"}/>
                </Row>
                <Row>
                  <div style={{ flex: 1 }}>
                    <Button
                      style={{
                        backgroundColor: "#F8F8F9",
                        color: "#F92C78"
                      }}
                      onClick={()=>{history.push('/admins')}}
                    >
                      Cancel
                    </Button>
                  </div>
                <Button 
                  type="submit" 
                  onClick={handleSubmit}
                  disabled={loadingButton == true}
                  >
                    {
                    loadingButton?
                    <CircularProgress size={20} thickness={5} style={{'color': 'white'}} />
                    :
                    "Save"
                    }
                </Button>
                </Row>
            </div>
           
        </Row>
        </Column>
        </>
    )
}

export default EditAdmin