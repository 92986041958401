import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  icon: {
    // padding: theme.spacing(2),
    cursor: "pointer",
    transition: "color .5s ease, transform .5s ease ",
    transform: "rotate(0)",
    "&:hover": {
      color: "#fe9104",
      transform: "rotate(360deg)"
    }
  }
  // spacing: {
  //   marginTop: theme.spacing(2),
  //   marginBottom: theme.spacing(2),
  // },
  // textArea:{
  //   minHeight:150,
  //   '& textarea': {
  //     minHeight:213,
  //   }
  // },
  // dropZone:{
  //   background: 'transparent',
  //   border: '1px dashed #929292',
  //   borderRadius: 5,
  //   '& p':{
  //     fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
  //     fontSize: 15,
  //     color: '#929292'

  //   }
  // },
  // zoneTile: {
  //   background: 'rgba(204, 204, 204, 0.12156862745098039)',
  //   padding: 15,
  //   textAlign: 'center',
  //   border: '1px solid #929292',
  //   color: '#fff',
  // },
  // chipList: {
  //   padding: theme.spacing(0.5),
  //   display: 'flex',
  //   justifyContent: 'center',
  //   flexWrap: 'wrap',
  //   listStyle: 'none',
  //   margin: 0,
  //   minHeight: 40
  // },
  // chip: {
  //   margin: theme.spacing(0.5),
  // },
  // addInput: {
  //   padding: '2px 4px',
  //   display: 'flex',
  //   alignItems: 'center',
  //   minWidth: 250,
  // },
  // input: {
  //   marginLeft: theme.spacing(1),
  //   flex: 1,
  // },
  // iconButton: {
  //   padding: 10,
  // },
  // divider: {
  //   height: 28,
  //   margin: 4,
  // },
}));
