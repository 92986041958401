/**
 *
 * LevelPage
 *
 */

import React from "react";
import Breadcrumbs from 'components/Breadcrumbs';
import ReferredList from "./RefferedList";

const ReferredListPage = () => {
  return (
    <div>
      <Breadcrumbs title="Reffered list" breads={window.location.pathname} />
      <div style={{overflowX: "hidden", position: "relative"}}>
      <ReferredList/>
      </div>
    </div>
  );
}

export default (ReferredListPage);
