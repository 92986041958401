import * as Yup from "yup";

// serviceName: "",
// nbActionsToRef: 0,
// maxEarning: 0,
// referrerAmountEarning: 0,
// referredAmountEarning: 0,
// isRefActivated: false,
// doNotChangeBadges: false,

export default Yup.object().shape({
  serviceName: Yup.string()
    .min(1)
    .required("Error.CountryActivation.serviceName.required"),

  nbActionsToRef: Yup.number()
    .min(1, "Error.CountryActivation.nbActionsToRef.Positive")
    .required("Error.CountryActivation.nbActionsToRef.required"),

  maxEarning: Yup.number()
    .min(1, "Error.CountryActivation.maxEarning.Positive")
    .required("Error.CountryActivation.maxEarning.required"),

  referrerAmountEarning: Yup.number()
    .min(1, "Error.CountryActivation.referrerAmountEarning.Positive")
    .required("Error.CountryActivation.referrerAmountEarning.required"),

  referredAmountEarning: Yup.number()
    .min(1, "Error.CountryActivation.referredAmountEarning.Positive")
    .required("Error.CountryActivation.referredAmountEarning.required"),

    validDays: Yup.number()
    .min(1, "Error.CountryActivation.validDays.Positive")
    .required("Error.CountryActivation.validDays.required"),

    yassirCouponName: Yup.string()
    .min(2)
    .required("Error.CountryActivation.yassirCouponName.required"),

    yassirSegmentName: Yup.string()
    .min(2)
    .required("Error.CountryActivation.yassirSegmentName.required"),

    expressCouponName: Yup.string()
    .min(2)
    .required("Error.CountryActivation.expressCouponName.required"),
});
